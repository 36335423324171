import React, { useContext, useEffect, useState } from "react";
import AppContext from "../contexts/AppContext";
import WithLoader from "./WithLoader";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import Select from "react-select";
import renderComponent from "../renderComponent";
import moment from "moment";
import { CalendarIcon } from "@heroicons/react/solid";
import ReactDatePicker from "react-datepicker";
import Spinner from "../loaders/Spinner";

window._moment = moment;

function MainArea1({ setLoading, subcomponents, type }) {
  return (
    // <div className="px-12 pt-0 flex-1 overflow-auto">
    <>
      <div className="lg:px-8 px-4 pb-8 pt-14 lg:pt-8 w-rb overflow-auto">
        <div className="">
          <div>
            {subcomponents?.map((config, idx) => renderComponent(config))}
          </div>
        </div>
      </div>
    </>
  );
}

export default WithLoader(MainArea1);
