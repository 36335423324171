import React from "react";

function ContentArea({ children }) {
  return (
      <>
        {children}
      </>
  );
}

export default ContentArea;