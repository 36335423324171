// export const openNewPage = ({currentTarget:target}) => {
//     console.log("target",target);
//     let link = target.dataset["link"].toLowerCase()

//     if(link){
//         window.location.pathname = link;
//     }
// }

export const openNewPage = ({currentTarget:target}) => {
    let link = `/${target.dataset["link"].toLowerCase()}`
    
    console.log("link",link);

    fetch(link,{},1000).then(res => res.json()).then(res => {  
        
    })

    // if(link){
    //     window.location.pathname = link;
    // }
}

export const openModuleItem = ({currentTarget:target}) => {
    let moduleItem = target.dataset["moduleItem"];
    console.log("moduleItem",moduleItem);
}