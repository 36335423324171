
export const appendQueryToUri = (uri, json) => {

    for(var prop in json){
        if(json[prop] == null){
            delete json[prop]
        }
    }
    return `${uri}?${new URLSearchParams(json).toString()}`
}


// export function groupBy(arr,groupArg){
//     let result = {}
//     arr.forEach(m => {
//         if(!result[m[groupArg]]) result[m[groupArg]] = [];
//         result[m[groupArg]].push(m);
//     })
//     return result;
// }

export function getRandomValue(min, max) {
    const randomDecimal = Math.random();
    const randomValue = randomDecimal * (max - min) + min;
    const roundedValue = Math.round(randomValue);
    return roundedValue;
  }

  export const debounce = (callback, delay) => {
    let timerId;
  
    return (...args) => {
      clearTimeout(timerId);
  
      timerId = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  export const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  export const getRootPath = () => {
    const pathname = window.location.pathname;
    const regex = /^\/([^\/]+)/;
    const match = pathname.match(regex);
    var subscriber = match ? match[1] : null
    return subscriber;
  }

  export function isValidValue(value) {
    return value !== null && value !== undefined && value !== '';
  }

  export function groupBy(arr, keyFn) {
    return arr.reduce((result, item) => {
      const key = keyFn(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});
  }