import React, {useEffect, useState} from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, CalendarIcon, ArrowsExpandIcon } from '@heroicons/react/solid'
import { Button, PageButton } from '../shared/Button'
import classNames from 'classnames'
import { SortIcon, SortUpIcon, SortDownIcon } from '../shared/Icons'
import "../responsivetable.css"
import { appendQueryToUri, debounce } from '../util/generalUtil'
import ReactDatePicker from 'react-datepicker'
import { useTheme } from 'styled-components'
import moment from 'moment'
import CloseIcon from '../icons/CloseIcon'
import appFetch from '../appFetch'
import apiEndpoints from '../apiEndpoints'
import Spinner from '../loaders/Spinner'
import TransformValue from './TransformValue'

var _moment = moment

const excludeKeys = [
  "drillable",
  "drillid",
  "target_name",
  "target_type",
  "targetid"
]

 const InjectHtml = ({ value }) => <span dangerouslySetInnerHTML={{__html: value}} />

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchRef
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div class="relative text-gray-600">
        <input 
            ref={searchRef}
            className="border-2 border-gray-300 bg-white h-10 px-5 pr-8 rounded-lg text-sm focus:outline-none w-full"
            autoComplete='off'
            value={value || ""}
            onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
            }}
            placeholder={`search ${count} records...`}
        />
        <button type="submit" class="absolute right-3 top-2/4 -translate-y-2/4">
        <svg className="text-gray-600 h-4 w-4 fill-current" 
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            viewBox="0 0 56.966 56.966"
            width="512px" height="512px">
            <path
            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
        </svg>
        </button>
    </div>
    // <label className="flex gap-x-2 items-baseline">
    //   <span className="text-gray-700">Search: </span>
    //   <input
    //     type="text"
    //     className="rounded-md px-2 py-1 border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    //     value={value || ""}
    //     onChange={e => {
    //       setValue(e.target.value);
    //       onChange(e.target.value);
    //     }}
    //     placeholder={`${count} records...`}
    //   />
    // </label>
  )
}

const TableCell = ({cell,columnNames,mobileTableVisible,rowNum,rowExpanded, setRowExpanded}) => {
  const [firstCell] = useState(columnNames.indexOf(cell.column.Header) == 0)
  const isRowExpanded = rowExpanded[rowNum]
  //console.log("tableCellData",{cell,columnNames,mobileTableVisible,rowNum,rowExpanded, setRowExpanded})

  const expandRow = () => {
      if(!mobileTableVisible) return
      var expandedRows = [...rowExpanded];
      expandedRows[rowNum] = isRowExpanded ? false : true;
      setRowExpanded(expandedRows);
  }

  return (
      <td {...cell.getCellProps()}
          onClick={expandRow}
          className={classNames("px-6 py-4 whitespace-nowrap text-sm text-gray-500",{
          "dtr-control sorting_1":firstCell && mobileTableVisible,
          "hidden": mobileTableVisible && columnNames.indexOf(cell.column.Header) > 1 
          }
          )}
          role="cell"
      >
        {cell.render(InjectHtml)}
      </td>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border border-gray-300 p-1 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  )
}

const TableRow = ({drillable,row, columnNames,mobileTableVisible,rowNum,rowExpanded,setRowExpanded,theme}) => {
  const [drill,showDrill] = useState(null)
  const [loading,setLoading] = useState(false)

  const showDetail = (row) => {
    console.log("the line is", row);
    const {original:item} = row;
    setLoading(true)

    let postData = {    
        "layoutId": item?.layoutid,
        "targetId": item?.targetid,
        "target": item?.target,
        "drillId":item.drillid?.toString(),
        "targetName":item?.target_name,
        "targetType":item?.target_type,
    }

    appFetch.get(appendQueryToUri(apiEndpoints.menuView,postData)).then(res => {
        let options = (res.options ?? [])[0] ?? null
        showDrill(options)
    })
    .catch(e => console.log(e.message))
    .finally(() => {
      setLoading(false)
    });
}

  return (
    <>
    <tr {...row.getRowProps()} className={classNames({
        "dt-hasChild parent": rowExpanded[rowNum]
      })}>
      <>
      {row.cells.map((cell,idx) => 
        <TableCell 
            key={idx} 
            cell={cell} 
            columnNames={columnNames} 
            mobileTableVisible={mobileTableVisible} 
            rowNum={rowNum} 
            rowExpanded={rowExpanded}
            setRowExpanded={setRowExpanded} 
        />
        )}
      </>
      {drillable && 
        <td class="px-4 py-4">
            <button className='inline-flex items-center justify-center w-7 h-7 rounded-md bg-background-yellow shadow hover:text-main-black' 
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if(drill){
                  showDrill(null)
                  return;
              }
              showDetail(row)
            }}>
            {drill ? <CloseIcon fill={theme?.colors?.primary} width={10} /> :
            <ArrowsExpandIcon fill={theme?.colors?.primary} width={18} />
            }
            </button>
        </td>}
      </tr>
        {rowExpanded[rowNum] && mobileTableVisible && <tr className='child'>
          <td className='child' colSpan={2}>
          <div className="w-full p-3">
              <ul className="dtr-details w-full">
                  {columnNames?.slice(2)?.map((str, index) => (
                      <li key={index}>
                          <span className="dtr-title pr-2">{str}</span> 
                          <span className="dtr-data">{row.values[str]}</span>
                      </li>
                      ))}
                  </ul>
              </div>
          </td>
      </tr>
      }
      {(loading || drill) && <tr>
        <td colSpan={row.cells.length + (drillable ? 1 : 0)}>
          {loading && <div className='grid place-items-center py-6'>
            <Spinner fill="#e2e3e4" width={42} height={42} />
          </div>}

          {drill?.gridtype === "horizontal" &&
            (() => {
              let drillKeys = Object.keys((drill?.grid ?? [])[0] ?? {}).filter(head => !["drillable", "target_name", "target_type", "drillid","targetid"].includes(head))

              return (

              <div className="overflow-auto">
                <div class="p-6 bg-gray-100 w-full">
                  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          {drillKeys?.map((str, idx) => (
                            <th scope="col" class="px-4 py-3 text-xs" key={idx}>
                              {str}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {drill?.grid?.map((line, key) => {
                          return (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              {drillKeys?.map((str, idx) => (
                                <td class="px-4 py-4 text-xs">
                                  <TransformValue value={line[str]} />
                                </td>
                              ))}
                            </tr>
                          )
                        })}
                        {drill?.grid?.length === 0 && <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td class="px-4 py-4 text-xs">
                            <span className='flex justify-center'>No record available.</span>
                          </td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              )
            })()
          }

          {drill?.gridtype === "vertical" && (() => {
            const objKeys = Object.keys((drill?.grid ?? [])[0] ?? {}).filter(key => !excludeKeys.includes(key))
            return (
              <div class="p-6 w-full">
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <ul className='modal-list'>
                    {drill?.grid.map(record => (
                      <li className='modal-list-item list-none'>
                        <ul className='grid gap-1 my-6 border-b border-gray-200 mb-3'>
                          {objKeys.map((str, idx) => {
                            return (
                              <li className={classNames('flex justify-between')} key={idx}>
                                <div className={classNames("py-2 px-3 w-36", { "bg-gray-200": ((idx + 1) % 2) != 0 })}><span className='text-gray-700 font-semibold text-xs'>{str}</span></div>
                                <div className={classNames("ml-0.5 py-2 px-3 flex-grow", { "bg-gray-200": ((idx + 1) % 2) != 0 })}>
                                  <span className='text-xs'>
                                    <TransformValue value={record[str]} />
                                  </span>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          })()}
        </td>
      </tr>}
  </>
  )
}


function Table({ filterByDateRange,columns, data,drillable,searchRef }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
  },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,  // new
  )

  const theme = useTheme()
  const columnNames = columns?.map(({Header}) => Header)
  const [mobileTableVisible, setMobileTableVisible] = useState(window.innerWidth <= 640);
  console.log("responsvie", {width:window.innerWidth, mobileTableVisible})

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [rowExpanded,setRowExpanded] = useState((new Array(data.length)).fill(false))

  const currentYear = (new Date()).getFullYear();

  const minDate = new Date(currentYear, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  useEffect(() => {
    const handleResize = debounce(() => {
      const isMobileMode = window.innerWidth <= 640;
      setMobileTableVisible(isMobileMode)
    },300);

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  const pages = [10, 20, 30, 40, 50]
  .filter(x => x <= data.length)

  // Render the UI for your table
  return (
    <>
      <div className="flex gap-x-2 gap-y-1.5 items-center justify-between bg-gray-100 rounded-md px-2 py-2">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
        <GlobalFilter
          searchRef={searchRef}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />


        <div className='flex items-center justify-between border-2 border-gray-300 bg-white h-10 pl-3 rounded-lg text-sm focus:outline-none'>
          <ReactDatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                wrapperClassName='bg-transparent focus:outline-none'
                dateFormat={"MMM, d"}
                onChange={(update) => {
                  setDateRange(update);
                  if(moment(update[0])._isValid && moment(update[1])._isValid){
                    const dateFilter = {
                      startDate:moment(update[0]).format('yyyy-MM-D'),
                      endDate:moment(update[1]).format('yyyy-MM-D')
                    }
                    filterByDateRange(dateFilter)
                  }

                  if(!moment(update[0])._isValid && !moment(update[1])._isValid){
                    filterByDateRange(null)
                  }
                }}
                placeholderText='filter by date'
                clearButtonClassName='text-primary hover:bg-primary rounded-md'
                //isClearable={true}
                clear='close'
              />
              <div className='flex-1 pr-2'>
                <CalendarIcon width={20} height={20} fill="#9e9e9e"/>
              </div>
        </div>
        
      </div>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-auto table-scrollbar scrollbar scrollbar-h-1 scrollbar-track-gray-200 scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full -mx-4 sm:-mx-6">
        {/* <div className="-my-2 overflow-auto -mx-4 sm:-mx-6"> */}

          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 dataTable dtr-inline collapsed bg-gray-200">
                <thead className="bg-gray-200">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope="col"
                          className={classNames("group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",{
                            "hidden": mobileTableVisible && columnNames.indexOf(column.Header) > 1 
                          })}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <SortDownIcon className="w-4 h-4 text-gray-400" />
                                  : <SortUpIcon className="w-4 h-4 text-gray-400" />
                                : (
                                  <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {!data?.length && <tr>
                    <td colSpan={mobileTableVisible ? 2 : columns.length}>
                      <div className="flex w-full p-4 items-center justify-center">
                          <span>no record found!</span>
                      </div>
                    </td>
                    </tr>}
                  {page.map((row, i) => {  // new
                    prepareRow(row)
                    return (
                      <TableRow 
                          theme={theme}
                          row={row}
                          rowNum={i}
                          columnNames={columnNames}
                          rowExpanded={rowExpanded}
                          setRowExpanded={setRowExpanded}
                          drillable={drillable}
                          mobileTableVisible={mobileTableVisible}
                      />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}

      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden items-center">
          <div className="flex-1 flex gap-x-2 items-baseline">
            <span className="text-sm text-gray-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              {pages?.length > 0 &&
              <select
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                 {pages.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
                {data.length > pages.slice(-1)[0] && <option key={data.length} value={data.length}>
                    {data.length}
                  </option>}
              </select>
              }
            </label>
          </div>
          {data.length > state.pageSize && 
          <div className='gap-x-2 flex justify-between'>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Prev</Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
          </div>
          }
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <span className="text-sm text-gray-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              {pages?.length > 0 &&
              <select
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {pages.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
                 {data.length > pages.slice(-1)[0] && <option key={data.length} value={data.length}>
                    Show {data.length}
                  </option>}
              </select>
              }
            </label>
          </div>
          {data.length > state.pageSize && 
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => nextPage()}
                disabled={!canNextPage
                }>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
            </nav>
          </div>
            }
        </div>
      </div>
    </>
  )
}

export default Table;