import React from "react";
import renderComponent from "../renderComponent";
import classNames from 'classnames';

const layoutRule = [
    "col-start-1",
    "col-start-2",
    "col-start-3",
    "col-start-1 col-end-4", 
]

function ParentMerge3MiddleArea({subcomponents}) { 
 console.log("children of parent merge 3", subcomponents)
  return (
      <div className="space-x-4 grid grid-cols-3 h-full flex-1 p-4 overflow-auto">
          {subcomponents.map((config, idx) => (
              <section key={idx} className={classNames(layoutRule[idx])}>
                  {renderComponent(config)}
              </section>
          ))
          }
      </div>
  );
}

export default ParentMerge3MiddleArea;
