import { createContext, useContext } from "react"
import { DefaultSubscriber } from "../constants/variables";
import { getRootPath } from "../util/generalUtil";

const SubscriberContext = createContext(null);

export const SubscriberProvider = ({children}) => {
   var subscriber = DefaultSubscriber ?? getRootPath()
    var subs = {"subs":subscriber}
    return (
        <SubscriberContext.Provider value={subs}>
            {children}
        </SubscriberContext.Provider>
    )
}

export const useSubscriber = () => {
    const subscriber = useContext(SubscriberContext);
    return subscriber;
}