import * as React from "react";

const FileIcon = (props) => (
  <svg
    viewBox="0 0 15 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 0V5.34375C7.5 5.81617 7.69754 6.26924 8.04917 6.60328C8.40081 6.93733 8.87772 7.125 9.375 7.125H15V17.2188C15 17.6912 14.8025 18.1442 14.4508 18.4783C14.0992 18.8123 13.6223 19 13.125 19H1.875C1.37772 19 0.900805 18.8123 0.549175 18.4783C0.197544 18.1442 0 17.6912 0 17.2188V1.78125C0 1.30883 0.197544 0.855765 0.549175 0.521716C0.900805 0.187667 1.37772 0 1.875 0H7.5ZM8.75 0.296875V5.34375C8.75 5.50122 8.81585 5.65224 8.93306 5.76359C9.05027 5.87494 9.20924 5.9375 9.375 5.9375H14.6875L8.75 0.296875Z"
      fill="inherit"
    />
  </svg>
);

export default FileIcon;
