import React from 'react';
import HomeIcon from "../icons/HomeIcon"
import icons from "../icons"
import classNames from 'classnames';

function ButtonStyle1({children,order,icon}) {
    return (
       <button className="flex py-2 px-4 rounded-md items-center text-white" style={{backgroundColor:"#CA5D99"}}>
           {icon && React.createElement(icons[icon], {
               width:24,
               height:24,
               fill:"#ffffff", 
               className:`order-${order}`
           })}
           <span className="ml-2 tracking-wide uppercase text-md">{children}</span>
        </button>
    );
}

export default ButtonStyle1;