export const isValidUrl = urlString=> {
    try { 
        return Boolean(new URL(urlString)); 
    }
    catch(e){ 
        return false; 
    }
}

export const mapOperator = (type) => {
    return {
      add(a,b) {
        return a+b
      },
      subtract(a,b) {
        return a-b
      },
      multiply(a,b) {
        return a*b
      },
      divide(a,b) {
        return a/b
      }
    }[type];
  }