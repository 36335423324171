import React, { useState } from "react";
import styled from "styled-components";
import formatter from "../util/formatter";
import { Dialog } from "@headlessui/react";
import ReactJson from "react-json-view";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import { useContext } from "react";
import AppContext from "../contexts/AppContext"

const PreviewContainer = styled.div`
  width: 100%;
  min-width: 960px;
  background: #fff;
`;

const filterPropsByName = (namesToRemove, sourceArray) => {
  return sourceArray.filter((c) => !namesToRemove.includes(c.name));
};

const getObjectByName = (name, sourceArray) => {
  let item = sourceArray.find((o) => o.name == name);
  return item || {};
};
function PrintPreview({ data }) {
  let {
    summary,
    amount,
    buyer_seller,
    company,
    button,
    currency,
    status,
    document,
    remark,
  } = data;

  let lines = data?.line_data;
  let header = (data?.line_header ?? [])[0]?.name;
  let attachments = (data?.attachment ?? []).map(({name}) => name)?.flat();

  header = header?.filter((m) => !/download|attachment/i.test(m));

  const {context} = useContext(AppContext)
  console.log("this is the print preview", data,context);

  return (
    <PreviewContainer>
      <div className="mt-2">
        <div className="border border-color-primary rounded-md overflow-hidden">
          <div id="print-preview">
          <div className="flex bg-gray-100 justify-between px-20 w-full py-10 mb-4">
            <div className="bg-primary p-5 flex flex-col w-60 self-start">
              <span className="text-sm text-white capitalize">status</span>
              <span className="text-lg text-white capitalize">
                {status[0]?.value}
              </span>
            </div>
            <div className="grid  w-60 ">
              {(() => {
                return (
                  <>
                    <span className="text-lg uppercase mb-3">
                      {document[0]?.value}
                    </span>
                    <div className="grid gap-2">
                      <div className="flex flex-col">
                        <span class="text-primary font-medium">
                          {document[1]?.name}:
                        </span>
                        <span class="text-gray-500">{document[1]?.value}</span>
                      </div>
                      <div className="flex flex-col">
                        <span class="text-primary font-medium">Date:</span>
                        <span class="text-gray-500">{document[2]?.value}</span>
                      </div>
                    </div>
                  </>
                );
              })()}
            </div>
          </div>
          <div className="flex justify-between px-20 w-full mb-4">
            {(() => {
              const logo = getObjectByName("Logo", company)?.value;
              console.log("logo",logo)
              const email = getObjectByName("Email", company);
              const phoneNumber = getObjectByName("Phone Number", company);
              const others = filterPropsByName(
                ["Logo", "Email", "Phone Number"],
                company
              );
              const unique = [...new Set(others.map((item) => item.value))];
              console.log("others", others);
              return (
                <div className="w-60 ">
                  {logo && (
                    <div className="logo">
                      <img src={logo} />
                    </div>
                  )}
                  <div className="grid">
                    <div className="mb-2 grid">
                      {/* others.map({name, value}) */}
                      {unique.map(
                        (value) => (
                          // <span className='font-medium text-black'>Swift Nig Ltd</span>
                          <span className="text-gray-600">{value}</span>
                        )
                        // console.log(value)
                      )}
                    </div>
                    {email.value && (
                      <div className="mb-2 grid">
                        <span className="font-medium text-black">Email</span>
                        <span className="text-gray-600">{email.value}</span>
                      </div>
                    )}
                    {phoneNumber.value && (
                      <div className="mb-2 grid">
                        <span className="font-medium text-black">Phone</span>
                        <span className="text-gray-600">
                          {phoneNumber.value}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })()}

            {(() => {
              const logo = (context?.app?.logo || [])[0] || getObjectByName("Logo", buyer_seller)?.value;
              const email = getObjectByName("Email", buyer_seller);
              const phoneNumber = getObjectByName("Phone Number", buyer_seller);
              const others = filterPropsByName(
                ["Logo", "Email", "Phone Number"],
                buyer_seller
              );
              return (
                <div className="w-60 ">
                  {logo && <div className="logo">
                      <img src={logo} />
                    </div>}
                  <div className="grid">
                    <div className="mb-2 grid">
                      {others?.map(({ name, value }) => (
                        // <span className='font-medium text-black'>Swift Nig Ltd</span>
                        <span className="text-gray-600">{value}</span>
                      ))}
                    </div>

                    {email.value && (
                      <div className="mb-2 grid">
                        <span className="font-medium text-black">Email</span>
                        <span className="text-gray-600">{email.value}</span>
                      </div>
                    )}
                    {phoneNumber.value && (
                      <div className="mb-2 grid">
                        <span className="font-medium text-black">Phone</span>
                        <span className="text-gray-600">
                          {phoneNumber.value}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })()}
          </div>
          <div className="bg-gray-400 h flex justify-between px-20 items-center h-10">
            <div className="text-white w-60">
              Currency: ({currency[0]?.value})
            </div>
            <div className="text-white w-60">
              Exhange Rate: {currency[1]?.value}
            </div>
          </div>
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <div class="inline-block min-w-full overflow-hidden px-5">
              <table class="min-w-full leading-normal">
                <thead>
                  <tr>
                    {header?.map((str, idx) => {
                      return (
                        <th
                          key={idx}
                          class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          {str.replace(/_+/g, " ")}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {lines?.map((row, idx) => (
                    <tr>
                      {header?.map((str, idx) => {
                        return (
                          <td class="px-5 py-5 border-gray-200 bg-white text-sm">
                            {/* <p class="text-gray-900 whitespace-no-wrap">

                              {  row[str.toUpperCase()]}
                            </p> */}
                            <span
                              className="text-gray-900 whitespace-no-wrap"
                              dangerouslySetInnerHTML={{
                                __html: row[str.toUpperCase()],
                              }}
                            ></span>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  <tr align="right">
                    <td align="right" colspan={header?.length}>
                      <div class="flex justify-end">
                        <div className="border-t-2 bg-gray-800 mt-2"></div>
                        <div className="grid px-2 py-6">
                          {amount?.map(({ name, value }, idx) => (
                            <>
                              {value && (
                                <div className="flex gap-8 justify-between">
                                  <span>{name}</span>
                                  <span
                                    dangerouslySetInnerHTML={{ __html: value }}
                                  ></span>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                        <div className="border-b-2 bg-gray-300 mb-2"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-10 px-20">
            <p className="capitalize font-semibold">Remarks</p>
            <p className="text-gray-400">{remark?.value ?? "no remark"}</p>
          </div>
          {attachments && (
            <div className="mt-4 px-20 bg-gray-100 py-8">
              <p className="font-semibold">Uploads</p>
              <div className="flex gap-4">
                {attachments?.map((url) => (
                  <div
                    className="rounded-lg overflow-hidden border w-16 h-16 p-1 cursor-pointer hover:scale-110"
                  >
                    <a href={url} target="_blank" rel="noreferrer">
                      <img className="w-full h-full" src={url} alt="" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="bg-gray-400 h flex justify-between px-20 items-center min-h-10 leading-none py-4">
            <div className="text-white">
              Thanks for being a swift ng limited customer
            </div>
            <div className="text-white w-60">Need help? help@swift.com.ng</div>
          </div>
          <div className="px-20 py-5"></div>
        </div>
        </div>
      </div>
    </PreviewContainer>
  );
}

export default PrintPreview;
