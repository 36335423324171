import * as React from "react";

const BingoIcon = (props) => (
  <svg
    height="30"
    width="30"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512.002 512.002"
    {...props}
  >
    <path
      fill={props?.fill}
      d="M174.531,440c-4.4,0-10.545-2.546-13.657-5.657L45.658,319.127
	c-3.111-3.111-5.657-9.257-5.657-13.657V142.53c0-4.4,2.545-10.546,5.657-13.657L160.874,13.657C163.985,10.546,170.131,8,174.531,8
	h162.94c4.4,0,10.545,2.546,13.657,5.657l115.216,115.216c3.111,3.111,5.657,9.257,5.657,13.657v162.94
	c0,4.4-2.545,10.546-5.657,13.657L351.128,434.343c-3.111,3.111-9.257,5.657-13.657,5.657H174.531z"
    />
    <circle fill={props?.fill} cx="256" cy="224" r="16" />
    <circle fill={props?.fill} cx="152" cy="273" r="24" />
    <path
      fill={props?.fill}
      d="M168.001,289c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C169.285,288.857,168.663,289,168.001,289z"
    />
    <circle fill={props?.fill} cx="273" cy="286" r="24" />
    <path
      fill={props?.fill}
      d="M289.001,302c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C290.285,301.857,289.663,302,289.001,302z"
    />
    <circle fill={props?.fill} cx="216" cy="286" r="24" />
    <path
      fill={props?.fill}
      d="M232.001,302c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C233.285,301.857,232.663,302,232.001,302z"
    />
    <circle fill={props?.fill} cx="207" cy="335" r="24" />
    <path
      fill={props?.fill}
      d="M223.001,351c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C224.285,350.857,223.663,351,223.001,351z"
    />
    <circle fill={props?.fill} cx="256" cy="335" r="24" />
    <path
      fill={props?.fill}
      d="M272.001,351c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C273.285,350.857,272.663,351,272.001,351z"
    />
    <circle fill={props?.fill} cx="304" cy="335" r="24" />
    <path
      fill={props?.fill}
      d="M320.001,351c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C321.285,350.857,320.663,351,320.001,351z"
    />
    <circle fill={props?.fill} cx="329" cy="384" r="24" />
    <path
      fill={props?.fill}
      d="M345.001,400c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C346.285,399.857,345.663,400,345.001,400z"
    />
    <circle fill={props?.fill} cx="280" cy="384" r="24" />
    <path
      fill={props?.fill}
      d="M296.001,400c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C297.285,399.857,296.663,400,296.001,400z"
    />
    <circle fill={props?.fill} cx="233" cy="384" r="24" />
    <path
      fill={props?.fill}
      d="M249.001,400c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C250.285,399.857,249.663,400,249.001,400z"
    />
    <circle fill={props?.fill} cx="183" cy="384" r="24" />
    <path
      fill={props?.fill}
      d="M199.001,400c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C200.285,399.857,199.663,400,199.001,400z"
    />
    <circle fill={props?.fill} cx="145" cy="335" r="24" />
    <path
      fill={props?.fill}
      d="M161.001,351c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C162.285,350.857,161.663,351,161.001,351z"
    />
    <circle fill={props?.fill} cx="137" cy="159" r="24" />
    <path
      fill={props?.fill}
      d="M153.001,175c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C154.285,174.857,153.663,175,153.001,175z"
    />
    <circle fill={props?.fill} cx="96" cy="224" r="24" />
    <path
      fill={props?.fill}
      d="M112.001,240c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C113.285,239.857,112.663,240,112.001,240z"
    />
    <circle fill={props?.fill} cx="240" cy="88" r="24" />
    <path
      fill={props?.fill}
      d="M256.001,104c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C257.285,103.857,256.663,104,256.001,104z"
    />
    <circle fill={props?.fill} cx="376" cy="336" r="24" />
    <path
      fill={props?.fill}
      d="M392.001,352c-13.255,0-24-10.745-24-24c0-6.084,2.337-11.575,6.068-15.805
	c-12.335,1-22.068,11.212-22.068,23.805c0,13.255,10.745,24,24,24c7.171,0,13.534-3.209,17.932-8.195
	C393.285,351.857,392.663,352,392.001,352z"
    />
    <g>
      <path
        fill={props?.fill}
        d="M472.001,123.216L356.785,8c-4.636-4.636-12.758-8-19.313-8h-162.94c-6.555,0-14.678,3.364-19.313,8
		L40.001,123.216c-4.636,4.636-8,12.759-8,19.314v162.94c0,6.556,3.364,14.679,8,19.314L146.41,431.193L114.989,496H96.001
		c-4.418,0-8,3.582-8,8s3.582,8,8,8h23.959c0.012,0,0.023,0.002,0.035,0.002c0.009,0,0.019-0.002,0.028-0.002h39.978
		c4.418,0,8-3.582,8-8s-3.582-8-8-8h-27.23l25.818-53.251c4.64,3.155,10.774,5.251,15.941,5.251h162.94
		c5.167,0,11.302-2.096,15.941-5.251L379.231,496h-27.23c-4.418,0-8,3.582-8,8s3.582,8,8,8h39.978c0.009,0,0.019,0.002,0.028,0.002
		c0.012,0,0.023-0.002,0.035-0.002h23.959c4.418,0,8-3.582,8-8s-3.582-8-8-8h-18.988l-31.421-64.807l106.409-106.409
		c4.636-4.636,8-12.759,8-19.314V142.53C480.001,135.975,476.637,127.852,472.001,123.216z M168.001,384
		c0-5.906,3.225-11.062,7.998-13.834l-7.815,16.12C168.076,385.537,168.001,384.778,168.001,384z M192.001,336
		c0-5.468,2.76-10.299,6.957-13.186l-6.904,14.238C192.031,336.703,192.001,336.355,192.001,336z M343.818,386.286l-7.815-16.12
		c4.773,2.772,7.998,7.928,7.998,13.834C344.001,384.778,343.926,385.537,343.818,386.286z M256.001,320c8.822,0,16,7.178,16,16
		s-7.178,16-16,16c-8.822,0-16-7.178-16-16S247.179,320,256.001,320z M245.425,305.804c-1.438,0.506-2.829,1.111-4.166,1.809
		c1.016-1.306,1.935-2.688,2.742-4.143C244.444,304.269,244.917,305.049,245.425,305.804z M229.365,296.775l1.809-3.73
		C230.73,294.373,230.122,295.626,229.365,296.775z M216.814,322.66c4.325,2.867,7.187,7.773,7.187,13.34c0,8.822-7.178,16-16,16
		c-1.753,0-3.436-0.294-5.016-0.817L216.814,322.66z M193.354,371.046C197.372,373.955,200,378.672,200,384c0,8.822-7.178,16-16,16
		c-1.521,0-2.987-0.227-4.382-0.625L193.354,371.046z M232.001,368c8.822,0,16,7.178,16,16s-7.178,16-16,16s-16-7.178-16-16
		S223.179,368,232.001,368z M280.001,368c8.822,0,16,7.178,16,16s-7.178,16-16,16s-16-7.178-16-16S271.179,368,280.001,368z
		 M318.647,371.046l13.735,28.329C330.987,399.773,329.521,400,328,400c-8.822,0-16-7.178-16-16
		C312.001,378.672,314.63,373.955,318.647,371.046z M319.948,337.053l-6.904-14.238c4.197,2.887,6.957,7.718,6.957,13.186
		C320.001,336.355,319.971,336.703,319.948,337.053z M309.017,351.183c-1.58,0.523-3.263,0.817-5.016,0.817c-8.822,0-16-7.178-16-16
		c0-5.566,2.861-10.473,7.187-13.34L309.017,351.183z M272.001,304c-8.822,0-16-7.178-16-16c0-8.369,6.462-15.246,14.657-15.932
		l13.014,26.841C280.75,302.033,276.605,304,272.001,304z M256.001,232c-4.411,0-8-3.589-8-8s3.589-8,8-8c4.411,0,8,3.589,8,8
		S260.412,232,256.001,232z M253.101,247.818c0.952,0.115,1.918,0.182,2.9,0.182s1.948-0.066,2.9-0.182l4.536,9.355
		c-8.341,2.32-15.319,7.936-19.436,15.356c-0.498-0.897-1.037-1.769-1.616-2.61L253.101,247.818z M208.897,302.314
		c-5.263-2.623-8.896-8.046-8.896-14.314c0-8.822,7.178-16,16-16c2.449,0,4.762,0.569,6.839,1.557L208.897,302.314z
		 M345.471,428.686c-1.611,1.611-5.723,3.314-8,3.314h-162.94c-2.277,0-6.389-1.703-8-3.314l-0.756-0.756l6.809-14.043
		c3.548,1.36,7.396,2.113,11.417,2.113c9.551,0,18.132-4.211,24-10.868c5.868,6.657,14.449,10.868,24,10.868s18.132-4.211,24-10.868
		c5.868,6.657,14.449,10.868,24,10.868s18.132-4.211,24-10.868c5.868,6.657,14.449,10.868,24,10.868
		c4.021,0,7.869-0.753,11.417-2.113l6.809,14.043L345.471,428.686z M464.001,305.47c0,2.277-1.703,6.39-3.313,8L358.203,415.954
		l-5.59-11.528c4.61-5.546,7.388-12.667,7.388-20.426c0-16.436-12.456-30.01-28.423-31.795c2.806-4.757,4.423-10.294,4.423-16.205
		c0-17.645-14.355-32-32-32c-0.026,0-0.052,0.002-0.078,0.002l-2.12-4.371C303.216,296.023,304,292.103,304,288
		c0-14.402-9.566-26.61-22.678-30.611l-8.097-16.7c4.189-4.323,6.775-10.208,6.775-16.688c0-13.233-10.766-24-24-24
		c-13.234,0-24,10.767-24,24c0,6.48,2.586,12.365,6.775,16.688l-8.949,18.456C225.64,257.13,220.95,256,216.001,256
		c-17.645,0-32,14.355-32,32c0,7.832,2.833,15.012,7.522,20.58c-9.293,5.605-15.522,15.799-15.522,27.42
		c0,5.911,1.617,11.448,4.423,16.205c-3.988,0.445-7.754,1.629-11.165,3.407c4.22-5.422,6.742-12.227,6.742-19.612
		c0-17.645-14.355-32-32-32s-32,14.355-32,32s14.355,32,32,32c5.314,0,10.326-1.31,14.742-3.612
		c-4.219,5.422-6.742,12.227-6.742,19.612c0,7.759,2.777,14.88,7.388,20.426l-5.59,11.528L51.314,313.47
		c-1.61-1.61-3.313-5.723-3.313-8V142.53c0-2.277,1.703-6.39,3.313-8L166.531,19.314c1.611-1.611,5.723-3.314,8-3.314h162.94
		c2.277,0,6.389,1.703,8,3.314L460.688,134.53c1.61,1.61,3.313,5.723,3.313,8L464.001,305.47L464.001,305.47z M160.001,336
		c0,8.822-7.178,16-16,16s-16-7.178-16-16s7.178-16,16-16S160.001,327.178,160.001,336z"
      />
      <path
        fill={props?.fill}
        d="M152.001,240c-17.645,0-32,14.355-32,32s14.355,32,32,32s32-14.355,32-32S169.646,240,152.001,240z
		 M152.001,288c-8.822,0-16-7.178-16-16s7.178-16,16-16s16,7.178,16,16S160.823,288,152.001,288z"
      />
      <path
        fill={props?.fill}
        d="M96.001,192c-17.645,0-32,14.355-32,32s14.355,32,32,32s32-14.355,32-32S113.646,192,96.001,192z
		 M96.001,240c-8.822,0-16-7.178-16-16s7.178-16,16-16c8.822,0,16,7.178,16,16S104.823,240,96.001,240z"
      />
      <path
        fill={props?.fill}
        d="M240.001,56c-17.645,0-32,14.355-32,32s14.355,32,32,32s32-14.355,32-32S257.646,56,240.001,56z
		 M240.001,104c-8.822,0-16-7.178-16-16s7.178-16,16-16s16,7.178,16,16S248.823,104,240.001,104z"
      />
      <path
        fill={props?.fill}
        d="M376.001,304c-17.645,0-32,14.355-32,32s14.355,32,32,32s32-14.355,32-32S393.646,304,376.001,304z
		 M376.001,352c-8.822,0-16-7.178-16-16s7.178-16,16-16s16,7.178,16,16S384.823,352,376.001,352z"
      />
      <path
        fill={props?.fill}
        d="M136.001,192c17.645,0,32-14.355,32-32s-14.355-32-32-32s-32,14.355-32,32S118.356,192,136.001,192z
		 M136.001,144c8.822,0,16,7.178,16,16s-7.178,16-16,16s-16-7.178-16-16S127.179,144,136.001,144z"
      />
      <path
        fill={props?.fill}
        d="M320.001,32h-16c-4.418,0-8,3.582-8,8s3.582,8,8,8h16c4.418,0,8-3.582,8-8S324.419,32,320.001,32z"
      />
      <path
        fill={props?.fill}
        d="M351.765,48.235c-3.123-3.122-8.188-3.123-11.313,0c-3.125,3.125-3.125,8.189,0,11.314
		l61.868,61.868c1.562,1.562,3.609,2.343,5.657,2.343c2.047,0,4.095-0.781,5.657-2.343c3.125-3.125,3.125-8.189,0-11.314
		L351.765,48.235z"
      />
    </g>
  </svg>
);

export default BingoIcon;
