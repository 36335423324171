import React from 'react';
import { Dialog } from '@headlessui/react'
import renderComponent from '../renderComponent';
import ModalLayout1 from './ModalLayout1';
import { appendQueryToUri } from '../util/generalUtil';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import apiEndpoints from '../apiEndpoints';
import { DownloadIcon, XIcon } from '@heroicons/react/solid';
import WithLoader from './WithLoader';

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);


function DrillModalDialog({isOpen,item, closeModal, setLoading}) {


  console.log("item from drillmodal",item)
    
    return (
    <Dialog  as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {}} 
        open={isOpen}>
      <div className="min-h-screen h-full px-4 text-center">
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className=" flex justify-center items-center">
        <div className="inline-block w-full xl:max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
          {item?.type && renderComponent({...item, setLoading, handleCloseModal: closeModal})}
          {!item?.type && item?.options && 
          <ModalLayout1 
              handleCloseModal={closeModal} 
              options={item?.options} 
              layoutid={item?.layoutid} 
              target={item?.target}
              targetType={item?.target_type}
              targetid={item?.targetid} 
              drillid={item?.drillid} 
              setLoading={setLoading}/>
          }
        </div>
        </div>
      </div>
    </Dialog>
    );
}

export default WithLoader(DrillModalDialog);

