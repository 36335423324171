import React from "react";
import renderComponent from "../renderComponent";
import classNames from "classnames";

const layoutRule = ["col-start-1", "col-start-2"];

function ParentMerge2MiddleArea({ subcomponents }) {
  console.log("children of parent merge 2", subcomponents);
  return (
    <div className="space-x-6 grid grid-cols-2 h-full flex-1 p-4 overflow-auto pt-10">
      {subcomponents.map((config, idx) => (
        <section key={idx} className={classNames(layoutRule[idx])}>
          {renderComponent(config)}
        </section>
      ))}
    </div>
  );
}

export default ParentMerge2MiddleArea;
