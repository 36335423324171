import React, { useContext, useEffect, useState } from "react";
import api from "../endpoints";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FilterIcon from "../icons/FilterIcon";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import styled, { ThemeContext } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { appendQueryToUri } from "../util/generalUtil";
import { QuickNotificationReport } from "../constants/variables";
import WithLoader from "./WithLoader";
import { ArrowsExpandIcon } from "@heroicons/react/solid";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import DrillModalDialog from "./DrillModalDialog";
import EmptyIcon2 from "../icons/EmptyIcon2";
import icons from "../icons";

const QuickNotificationCard = styled.div`
  background: ${({ theme }) => theme?.colors?.accent};
`;

const iconMap = {
  "Undeployed Tenders": "UndeployedTenderIcon",
  "Active Tenders": "ActiveTenderIcon",
  "Closing Tenders": "ClosingTenderIcon",
  "Closed Tenders": "ClosedTenderIcon",
  Draw: "BingoIcon",
  Lottery: "LotteryIcon",
  numbers: "InputNumberIcon",
  bioData: "BioDataIcon",
  Online: "OnlineIcon",
  Users: "UsersIcon",
  Retail: "RetailIcon",
  Wallet: "WalletIcon",
  Ussd: "UssdIcon",
  Draws: "DrawsIcon",
  ThirdParty: "ThirdPartyIcon",
  bell: "BellIcon",
  computer: "ComputerIcon",
};

// let options = [
//   {
//     title: { name: "Visits by source", value: "29.9k Visitors" },
//     data: [
//       {
//         title: "Direct source",
//         subTitle: "Direct link clicks",
//         image: "",
//         icon: "",
//         value: "24,558",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Social Network",
//         subTitle: "All Social channels",
//         image: "",
//         icon: "",
//         value: "794",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Email Newsletter",
//         subTitle: "Mailchimp Campaigns",
//         image: "",
//         icon: "",
//         value: "6,578",
//         change: { direction: "down", value: "7.6%" },
//       },
//       {
//         title: "Referrals",
//         subTitle: "Impact Radius Visits",
//         image: "",
//         icon: "",
//         value: "79,458",
//         change: { direction: "up", value: "5.6%" },
//       },
//       {
//         title: "Rising Networks",
//         subTitle: "Social Networks",
//         image: "",
//         icon: "",
//         value: "18,047",
//         change: { direction: "down", value: "8.2%" },
//       },
//       {
//         title: "Others",
//         subTitle: "Many Sources",
//         image: "",
//         icon: "",
//         value: "1067",
//         change: { direction: "up", value: "1.2%" },
//       },
//     ],
//   },
//   {
//     title: { name: "Visits by source", value: "29.9k Visitors" },
//     data: [
//       {
//         title: "Direct source",
//         subTitle: "Direct link clicks",
//         image: "",
//         icon: "",
//         value: "24,558",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Social Network",
//         subTitle: "All Social channels",
//         image: "",
//         icon: "",
//         value: "794",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Email Newsletter",
//         subTitle: "Mailchimp Campaigns",
//         image: "",
//         icon: "",
//         value: "6,578",
//         change: { direction: "down", value: "7.6%" },
//       },
//       {
//         title: "Referrals",
//         subTitle: "Impact Radius Visits",
//         image: "",
//         icon: "",
//         value: "79,458",
//         change: { direction: "up", value: "5.6%" },
//       },
//       {
//         title: "Rising Networks",
//         subTitle: "Social Networks",
//         image: "",
//         icon: "",
//         value: "18,047",
//         change: { direction: "down", value: "8.2%" },
//       },
//       {
//         title: "Others",
//         subTitle: "Many Sources",
//         image: "",
//         icon: "",
//         value: "1067",
//         change: { direction: "up", value: "1.2%" },
//       },
//     ],
//   },
//   {
//     title: { name: "Visits by source", value: "29.9k Visitors" },
//     data: [
//       {
//         title: "Direct source",
//         subTitle: "Direct link clicks",
//         image: "",
//         icon: "",
//         value: "24,558",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Social Network",
//         subTitle: "All Social channels",
//         image: "",
//         icon: "",
//         value: "794",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Email Newsletter",
//         subTitle: "Mailchimp Campaigns",
//         image: "",
//         icon: "",
//         value: "6,578",
//         change: { direction: "down", value: "7.6%" },
//       },
//       {
//         title: "Referrals",
//         subTitle: "Impact Radius Visits",
//         image: "",
//         icon: "",
//         value: "79,458",
//         change: { direction: "up", value: "5.6%" },
//       },
//       {
//         title: "Rising Networks",
//         subTitle: "Social Networks",
//         image: "",
//         icon: "",
//         value: "18,047",
//         change: { direction: "down", value: "8.2%" },
//       },
//       {
//         title: "Others",
//         subTitle: "Many Sources",
//         image: "",
//         icon: "",
//         value: "1067",
//         change: { direction: "up", value: "1.2%" },
//       },
//     ],
//   },
//   {
//     title: { name: "Visits by source", value: "29.9k Visitors" },
//     data: [
//       {
//         title: "Direct source",
//         subTitle: "Direct link clicks",
//         image: "",
//         icon: "",
//         value: "24,558",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Social Network",
//         subTitle: "All Social channels",
//         image: "",
//         icon: "",
//         value: "794",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Email Newsletter",
//         subTitle: "Mailchimp Campaigns",
//         image: "",
//         icon: "",
//         value: "6,578",
//         change: { direction: "down", value: "7.6%" },
//       },
//       {
//         title: "Referrals",
//         subTitle: "Impact Radius Visits",
//         image: "",
//         icon: "",
//         value: "79,458",
//         change: { direction: "up", value: "5.6%" },
//       },
//       {
//         title: "Rising Networks",
//         subTitle: "Social Networks",
//         image: "",
//         icon: "",
//         value: "18,047",
//         change: { direction: "down", value: "8.2%" },
//       },
//       {
//         title: "Others",
//         subTitle: "Many Sources",
//         image: "",
//         icon: "",
//         value: "1067",
//         change: { direction: "up", value: "1.2%" },
//       },
//     ],
//   },
//   {
//     title: { name: "Visits by source", value: "29.9k Visitors" },
//     data: [
//       {
//         title: "Direct source",
//         subTitle: "Direct link clicks",
//         image: "",
//         icon: "",
//         value: "24,558",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Social Network",
//         subTitle: "All Social channels",
//         image: "",
//         icon: "",
//         value: "794",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Email Newsletter",
//         subTitle: "Mailchimp Campaigns",
//         image: "",
//         icon: "",
//         value: "6,578",
//         change: { direction: "down", value: "7.6%" },
//       },
//       {
//         title: "Referrals",
//         subTitle: "Impact Radius Visits",
//         image: "",
//         icon: "",
//         value: "79,458",
//         change: { direction: "up", value: "5.6%" },
//       },
//       {
//         title: "Rising Networks",
//         subTitle: "Social Networks",
//         image: "",
//         icon: "",
//         value: "18,047",
//         change: { direction: "down", value: "8.2%" },
//       },
//       {
//         title: "Others",
//         subTitle: "Many Sources",
//         image: "",
//         icon: "",
//         value: "1067",
//         change: { direction: "up", value: "1.2%" },
//       },
//     ],
//   },
//   {
//     title: { name: "Visits by source", value: "29.9k Visitors" },
//     data: [
//       {
//         title: "Direct source",
//         subTitle: "Direct link clicks",
//         image: "",
//         icon: "",
//         value: "24,558",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Social Network",
//         subTitle: "All Social channels",
//         image: "",
//         icon: "",
//         value: "794",
//         change: { direction: "up", value: "2.2%" },
//       },
//       {
//         title: "Email Newsletter",
//         subTitle: "Mailchimp Campaigns",
//         image: "",
//         icon: "",
//         value: "6,578",
//         change: { direction: "down", value: "7.6%" },
//       },
//       {
//         title: "Referrals",
//         subTitle: "Impact Radius Visits",
//         image: "",
//         icon: "",
//         value: "79,458",
//         change: { direction: "up", value: "5.6%" },
//       },
//       {
//         title: "Rising Networks",
//         subTitle: "Social Networks",
//         image: "",
//         icon: "",
//         value: "18,047",
//         change: { direction: "down", value: "8.2%" },
//       },
//       {
//         title: "Others",
//         subTitle: "Many Sources",
//         image: "",
//         icon: "",
//         value: "1067",
//         change: { direction: "up", value: "1.2%" },
//       },
//     ],
//   },
// ];

function MixedNotificationList2({ setLoading, name, options }) {
  const { context } = useContext(AppContext);
  const theme = useContext(ThemeContext);
  const cardLength = options?.length ?? 0;
  const [modalData, setModalData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    // centerPadding: '100px',
    speed: 500,
    slidesToShow: cardLength > 3 ? 3 : cardLength,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: cardLength > 3 ? 3 : cardLength,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: cardLength > 1 ? 1 : cardLength,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: cardLength > 1 ? 1 : cardLength,
          dots: true,
        },
      },
    ],
  };

  const showDetail = (item) => {
    console.log("item", item);

    // setLoading(true);
    let postData = {
      layoutId: item.layoutid,
      targetId: item.targetid,
      target: item.target,
      drillid: item.drillid,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        const { layoutid, targetid, drillid } = item;
        setModalData({
          ...res,
          layoutid,
          targetid,
          drillid,
          target: QuickNotificationReport,
        });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message));
    // .finally(() => setLoading(false));
  };

  console.log("let the chart options be", options);

  const HandleValue = (value) => {
    let color;
    let direction;

    console.log("the value is", value.length);

    if (value?.color === "green") {
      direction = "\u2191";
      color = "bg-green-200 text-green-700";
    } else if (value?.color === "red") {
      direction = "\u2193";
      color = "bg-red-200 text-red-700";
    } else if (value?.color === "orange") {
      direction = "";
      color = "bg-orange-200 text-orange-700";
    } else if (value?.color === "yellow") {
      direction = "";
      color = "bg-yellow-200 text-yellow-700";
    } else if (value?.color === "blue") {
      direction = "";
      color = "bg-blue-200 text-blue-700";
    }

    let style = `inline-block text-xs font-semibold ${color} rounded-lg px-3 text-end `;

    return (
      <span
        className={style}
        dangerouslySetInnerHTML={{ __html: value.value }}
      ></span>
    );
  };

  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
        />
      )}
      <div className="mb-4 pt-3">
        <Slider {...settings} className="">
          {options &&
            options?.map((item, idx) => {
              // let { title, data } = item;
              return (
                <div className="px-3">
                  <div className="border rounded-lg shadow-md mb-10 cursor-pointer">
                    <div className="px-6 pt-4">{item?.name}</div>
                    <div className="px-6 text-sm text-gray-400">
                      {item?.value}
                    </div>
                    {/* <span className="xl:text-2xl lg:text-lg  inline-block" dangerouslySetInnerHTML={{ __html: box_value ?? value}}></span> */}

                    {item.data.map((item, idx) => {
                      let drillable = true;
                      const parsedData = JSON.parse(item.change);
                      let QuickIcon = iconMap[item?.icon];

                      console.log("the icons are", QuickIcon);

                      return (
                        <>
                          <div
                            key={idx}
                            class="px-6 pt-4 pb-2 grid grid-cols-7 justify-between items-center relative w-full"
                          >
                            {/* <img src="nothing yet" className="grid" /> */}
                            {icons[QuickIcon] ? (
                              React.createElement(icons[QuickIcon], {
                                width: 28,
                                height: null,
                                fill: theme?.colors?.primary,
                              })
                            ) : (
                              <EmptyIcon2
                                width={26}
                                height={null}
                                fill={theme?.colors?.primary}
                              />
                            )}
                            <div className="grid col-span-3">
                              <span className="capitalize text-sm block truncate">
                                {item.title}
                              </span>
                              <span
                                className="capitalize text-sm block truncate text-gray-400"
                                dangerouslySetInnerHTML={{
                                  __html: item.subtitle,
                                }}
                              ></span>
                            </div>
                            <div className="grid col-span-2 pl-4">
                              <div className="col-span-2">
                                <span
                                  className="capitalize text-xs text-right"
                                  dangerouslySetInnerHTML={{
                                    __html: item.value,
                                  }}
                                ></span>
                              </div>
                              <div className="col-span-1">
                                {HandleValue(parsedData)}
                              </div>
                            </div>
                            {drillable && (
                              <div
                                className="flex-end pl-7 cursor-pointer hover:scale-110 transition-transform"
                                onClick={() => {
                                  showDetail(item);
                                }}
                              >
                                <ArrowsExpandIcon
                                  fill={theme?.colors?.primary}
                                  width={18}
                                />
                              </div>
                            )}
                          </div>

                          <hr className="border-dashed" />
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default WithLoader(MixedNotificationList2);
