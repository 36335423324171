
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import icons from "../icons";
import actions from '../actions';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';    
import ConfigContext from "../contexts/AppContext";
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid'
import styled, {ThemeContext } from 'styled-components'
import AppContext from "../contexts/AppContext";
import LeftBarContext from "../contexts/LeftBarContext";
import WithLoader from "./WithLoader";
import { createBrowserHistory } from 'history';
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import { appendQueryToUri } from "../util/generalUtil";

const Badge = styled.div`
    width:18px;
    height:18px;
    background: ${({theme, active}) => active ? theme?.colors.primary : theme?.colors.accent };
    font-size:.7em;
    color: ${({theme, active}) => active ? "#ffffff" : theme?.colors.primary }; 
`

const MenuText = styled.div`
    color: ${({theme, active}) => active ? theme.colors?.primary : "#838383"};
    font-weight: ${({active}) => active ? "600" : "normal"};
`

const MenuList = styled.div`
  z-index: 1000; /* Set the desired z-index value here */
`;

function MenuList2({options,name, setLoading, onClick}) {
    const {config,setConfig} = useContext(ConfigContext);
    const theme = useContext(ThemeContext)
    const {context,setContext} = useContext(AppContext)
    // const {menuCollapse,setMobileMenuOpen } = useContext(LeftBarContext)
    // const history = useHistory();
    const history = createBrowserHistory()
    const {location:{pathname}} = history;

    // const options = [
    //     {
    //         "name": "Home",
    //         "icon": "home",
    //         "target": "menu",
    //         "targetid": 1480
    //     },
    //     {
    //         "name": "Setup",
    //         "icon": "setup",
    //         "target": "menu",
    //         "targetid": 1481
    //     },
    //     {
    //         "name": "Transaction",
    //         "icon": "transaction",
    //         "target": "menu",
    //         "targetid": 1482
    //     },
    //     {
    //         "name": "Approval",
    //         "icon": "approval",
    //         "target": "menu",
    //         "badge": 1,
    //         "targetid": 1483
    //     },
    //     {
    //         "name": "Payroll Reports",
    //         "icon": "dashboard",
    //         "target": "menu",
    //         "targetid": 1487,
    //         "group": "reports"
    //     },
    //     {
    //         "name": "Other Reports",
    //         "icon": "dashboard",
    //         "target": "menu",
    //         "targetid": 1488,
    //         "group": "reports"
    //     },
    //     {
    //         "name": "ESS Reports",
    //         "icon": "dashboard",
    //         "target": "menu",
    //         "targetid": 1489,
    //         "group": "reports"
    //     },
    //     {
    //         "name": "Memo",
    //         "icon": "memo",
    //         "target": "menu",
    //         "badge": 0,
    //         "targetid": 1484,
    //         "target_type": "MEMO"
    //     },
    //     {
    //         "name": "Open",
    //         "icon": "open",
    //         "target": "menu",
    //         "badge": 1,
    //         "targetid": 1485,
    //         "target_type": "OPEN"
    //     },
    //     {
    //         "name": "Confirmation",
    //         "icon": "open",
    //         "target": "menu",
    //         "badge": 0,
    //         "targetid": 1486,
    //         "target_type": "CONFIRMED"
    //     },
        
    // ]


    

    let _pathname = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home"
    _pathname = decodeURI(_pathname.replace(/\//, "")).replace(/\-/g," ")
    let patt = RegExp(_pathname,"i")
    console.log("patt",patt)
    let activeMenu = options?.find(menu => patt.test(menu?.name));
    let {targetid:menuId} = activeMenu || {};

    const currentPath = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home"

    useEffect(() => {        
        setContext(prev => ({...prev,menuId}));
    }, [pathname])

    console.log("contextData", context)

    console.log('initial options', options);
    
    let groupedOrder = {}
    let optionsGroup = []
    let optionsGroupLen = -1;
    
    options?.forEach(o => {
        if(o.group){
            if(!groupedOrder[o.group]){
                groupedOrder[o.group] = ++optionsGroupLen;
            }

            if(!optionsGroup[groupedOrder[o.group]]){
                optionsGroup[groupedOrder[o.group]] = {
                    name:o.group,
                    icon:o.groupicon,
                    options:[o]
                }
                return;
            }

            optionsGroup[groupedOrder[o.group]]?.options.push(o);
            return;
        }
        
        optionsGroup[++optionsGroupLen] = o;
    });

    console.log('optionsGroup',optionsGroup);

    const loadPage = (item) => {
        
        setLoading(true);

          let postData = {    
            "urlMenu": null,
            "layoutId": null,
            "targetId": item.targetid,
            "target": 'MENU',
            "schemaId": null,
            "txnGroup": null,
            "parentDropValue":null
          }

        appFetch.get(appendQueryToUri(apiEndpoints.menuView,postData)).then(res => {
            let link = `${item.name.toLowerCase()}`
            history.push(link.replace(/\s/g,'-'));
            // setContextMenuId(menuId)
            let page = {...context.pageConfig};
            let [leftArea,MainArea,rightArea] = page.children;
            page.children = [leftArea, res, rightArea];
            
            setContext(prev => ({
                ...prev,
                menuId:item.targetid,
                pageConfig: page
            }))
            
            // console.log("recentPageData",page)
            // console.log("recentPageDataFromContext",page)
        })
        .catch(e => console.log(e.message))
        .finally(() => setLoading(false));
    }



    const openNewPage = (item) => {
        // setMobileMenuOpen(true);
        if((new RegExp(item.name,"i")).test(activeMenu?.name)) return;
        loadPage(item)   
    }


    // const setContextMenuId = (menuId) => {
    //     setContext(prev => ({...prev, menuId}))
    // }

    const iconMap = {
        "home": "HomeIcon",
        "setup": "SetupIcon",
        "transaction": "TransactionIcon",
        "approval": "ApprovalIcon",
        "memo": "MemoIcon",
        "open": "OpenIcon",
        "dashboard":"DashboardIcon",
        "confirmation":'ConfirmationIcon',
        "payrollReports": "PayrollReportsIcon",
        "otherReports": "OtherReportsIcon",
        "essReports": "EssReportsIcon",
        "bioData": "BioDataIcon",
        "":"setup"
    }

    const Tree = ({item})  => {
        console.log('MenuList3', item);
        const [showGroup, setShowGroup] = useState(false);
        const showSubOptions = (item) => {
            if(item.options) return setShowGroup(!showGroup);
            return openNewPage(item)
        }
        let {name,icon,badge,options} = item
        let menuIcon = iconMap[icon] || "setup";
        const patt = (new RegExp(`^${name}$`,"i"));
        const active = patt.test(activeMenu?.name)
        console.log(item);
        return ( 
            <div>          
            <li className="mb-3 flex" onClick={() => {showSubOptions(item)}}>
            {icons[menuIcon] && React.createElement(icons[menuIcon], {
                width:20,
                height:null,
                fill:active ? theme?.colors?.primary :"#AAAAAA",
                stroke:active ? theme?.colors?.primary :"#AAAAAA"
            })}
            <MenuText className={classNames("capitalize leading-1 text-gray-custom tracking-wide flex items-center",{"pl-3":!!icons[menuIcon]})}  active={active}>
                <span className="pr-2">{item.name}</span>
                {typeof badge != "undefined" && <Badge className="font-semibold rounded-sm inline-grid place-items-center rounded-xl">{badge}</Badge>}
                {item.options && <span className="rounded-full"><ChevronDownIcon className="h-5 w-5" color={theme.colors?.accent} aria-hidden="true"/> </span>}
            </MenuText>
            </li>
            <div>
            {item.options && showGroup && <TreeSub item={item.options}/>}
            </div>
            </div>      
        );
    };

    

    const TreeSub = ({item}) => {
        return (
            <MenuList className="absolute shadow-md">
            <ul className="absolute -mt-2 w-60 bg-white border border-gray-100 rounded-lg shadow-md">
            { item.map((option, idx) => {
                let {name,icon,badge} = option;
                let menuIcon = iconMap[icon];
                const patt = (new RegExp(`^${name}$`,"i"));
                const active = patt.test(activeMenu?.name)
                return (
                    <div>
                    <li key={idx} className="mb-2 mt-2 ml-6 flex items-center cursor-pointer " onClick={() => openNewPage(option)}>
                    {icons[menuIcon] && React.createElement(icons[menuIcon], {
                    width:20,
                    height:null,
                    fill:active ? theme?.colors?.primary :"#AAAAAA",
                    stroke:active ? theme?.colors?.primary :"#AAAAAA"
                })}     
                <MenuText className="flex capitalize pl-3 leading-1 text-gray-custom tracking-wide flex items-center" active={active}>
                    <span>{name}</span>
                    {typeof badge != "undefined" && <Badge className="font-semibold ml-2 rounded-sm inline-grid place-items-center rounded-xl">{badge}</Badge>}
                </MenuText>
                </li>  
                <hr className="border-1 ml-6 mr-6"/>
                </div>
            )})}

            </ul>
            </MenuList>
        );
    };


    return (
        <div className="pb-8"> 
            <ul className="flex pt-4 border rounded-md justify-between pl-3 pr-2">
                {optionsGroup?.map((item,idx) => {
                    let {name,icon,badge,options} = item
                    return (
                        <li key={idx} className=" cursor-pointer">
                            {<Tree item={item} />}
                        </li>
                )})}
            </ul>
        </div>
    );
}

export default WithLoader(MenuList2);