import React, { useState, Fragment, useContext } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import notification from "../icons/notification";
import styled, { ThemeContext } from "styled-components";

const options = [
  {
    name: "All",
    badge: 8,
    options: [
      {
        text: "A new tender Kano Supply has been uploaded",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
        read: true,
      },
      {
        text: "Your bid for Kano Meat Supply has been submitted",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
        read: true,
      },
      {
        text: "A new tender Kano Supply has been uploaded",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
      },
      {
        text: "Your bid for Kano Meat Supply has been submitted",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
      },
    ],
  },
  {
    name: "Transactions",
    badge: 3,
    options: [
      {
        text: "A new tender Kano Supply has been uploaded",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
      },
      {
        text: "Your bid for Kano Meat Supply has been submitted",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
      },
    ],
  },
  {
    name: "Others",
    badge: 1,
    options: [
      {
        text: "A new tender Kano Supply has been uploaded",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
      },
      {
        text: "Your bid for Kano Meat Supply has been submitted",
        time: "2h ago",
        category: "Bids",
        icon: "FileIcon",
      },
    ],
  },
];

const Icon = styled.div`
  width: 33px;
  height: 33px;
  background: ${({ theme, read }) => theme?.colors.accent};
  color: ${({ theme, read }) => theme?.colors.primary};
  /* border: 1px solid #ffdede; */
  box-sizing: border-box;
  border-radius: 5px;
`;

const NotificationItem = styled.li`
  background: ${({ theme, read }) => (read ? theme?.colors.accent : "inherit")};
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
`;

const TabHead = styled.div`
  box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
`;

function NotificationModal({ options }) {
  const dataOptions = options.map((o) => o.options);
  const theme = useContext(ThemeContext);

  const Badge = styled.span`
    width: 18px;
    height: 18px;
    font-size: 0.7em;
    color: ${({ theme, active }) =>
      active ? theme?.colors?.primary : "#BBBBBB"};
    background: ${({ theme, active }) =>
      active ? theme?.colors.accent : "#F3F3F3"};
  `;

  return (
    <div className="p-3 z-50 relative">
      <Tab.Group>
        <Tab.List as={TabHead} className="flex pt-3 px-3 -mx-3 gap-5 mb-4">
          {options &&
            options.map(({ name, badge, options }, idx) => {
              let index = idx + 1;
              return (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={classNames(
                        "text-sm font-medium capitalize py-3 block leading-normal",
                        {
                          "text-black border-b-2 border-color-primary":
                            selected,
                          "text-gray-400": !selected,
                        }
                      )}
                    >
                      {name}
                      {typeof badge != "undefined" && (
                        <Badge
                          active={selected}
                          className="rounded-sm inline-grid place-items-center text-white ml-1 font-bold"
                        >
                          {badge}
                        </Badge>
                      )}
                    </button>
                  )}
                </Tab>
              );
            })}
        </Tab.List>
        <Tab.Panels className="grid gap-3">
          {dataOptions.map((item, idx1) => (
            <Tab.Panel key={idx1}>
              <ul className="grid gap-3" key={idx1}>
                {item?.map((item, idx2) => {
                  let { text, time, category, icon } = item;
                  return (
                    <NotificationItem
                      key={idx2}
                      className="flex notification-item items-center"
                    >
                      {notification[icon] && (
                        <Icon className="icon grid place-items-center mr-2">
                          {React.createElement(
                            notification[icon],
                            {
                              width: 16,
                              height: 18,
                              fill: theme?.colors?.primary,
                            },
                            null
                          )}
                        </Icon>
                      )}
                      <div className="grid">
                        <p className="text-sm text-gray-600 leading-none">
                          {text}
                        </p>
                        <div className="flex text-xs">
                          {time} &bull; {category}
                        </div>
                      </div>
                    </NotificationItem>
                  );
                })}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default NotificationModal;
