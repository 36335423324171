import React, { useState, useContext, useEffect } from "react";
import actions from "../actions";
import renderComponent from "../renderComponent";
import SearchIcon from "../icons/SearchIcon";
import {
  ChevronRightIcon,
  ArrowRightIcon,
  PlusCircleIcon,
  ChevronLeftIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import uuid from "react-uuid";
import styled, { useTheme } from "styled-components";
import { ThemeContext } from "styled-components";

import recordWithFormData from "../resources/recordwithform.json";
import PlusRound from "../icons/PlusRound";
import RightArrowIcon from "../icons/RightArrowIcon";
import { toBeInTheDOM } from "@testing-library/jest-dom/dist/matchers";

import formStepperJson from "../formStepperData.json";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import WithLoader from "./WithLoader";
import EmptyRecord from "./EmptyRecord";
import SearchControl1 from "./SearchControl1";
import { appendQueryToUri } from "../util/generalUtil";

function PageCycle({ data }) {
  return <> {data && renderComponent(data)} </>;
}

const AddRecordButton = styled.button`
  background: ${({ theme }) => theme?.colors?.primary};
`;

const BackButton = styled.button`
  width: 34px;
  height: 34px;
  background: #f6f6f6;
`;

const ModuleArrowToggler = styled.div`
  width: 26px;
  height: 26px;
  background: #fcfcfc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
`;

function FormList(props) {
  console.log("props from formlist", props);
  let { form, formDetail, module, handleFormChange, handleModuleChange } =
    props;
  let options = module?.options;
  const [search, setSearch] = useState(null);

  options = options?.filter((item) =>
    search == null
      ? true
      : item.name.toLowerCase().search(search.toLowerCase()) !== -1
  );

  const handleSearch = (() => {
    let timeout;

    return (e) => {
      let value = e.target.value;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(value);
      }, 300);
    };
  })();

  const reInit = () => setSearch(null);

  return (
    <div className="lg:h-full lg:overflow-auto px-8 py-8">
      {/* <div className="search-bar border inline-flex items-center border-gray-custom lg:w-96 w-full pl-4 rounded-xl mb-8">
                <SearchIcon fill="#333333" className="mr-3"/>
                <input type="text"className="rounded-xl h-12 w-full outline-none" placeholder="Search" onKeyUp={handleSearch}/>
            </div> */}

      <header className="capitalize text-md mb-4 flex gap-1 items-center">
        <div
          className={classNames(
            "sm:flex flex-none items-center relative top-2"
          )}
        >
          {module?.name && (
            <>
              {" "}
              {form?.name ? (
                <button
                  onClick={() => handleModuleChange(module)}
                  className="appearance-none flex cursor-pointer hover:underline hover"
                >
                  {module?.name}
                </button>
              ) : (
                <button className="appearance-none cursor-text">
                  {module?.name}
                </button>
              )}
            </>
          )}
          {form?.name && (
            <div className="sm:flex-none flex">
              <ChevronRightIcon width={20} height={20} />
              <span className="capitalize" style={{ color: "#838383" }}>
                {form?.name}
              </span>
            </div>
          )}
        </div>
      </header>
      {form == null ? (
        <>
          <SearchControl1 handleSearch={handleSearch} />
          <ul>
            {" "}
            {options?.map((item, idx) => {
              let { name, description } = item;
              return (
                <li
                  className="shadow-1/2 flex border border-md rounded-lg shadow-1/2 mb-4 cursor-pointer items-center gap-6 p-4"
                  key={idx}
                  onClick={() => handleFormChange(item)}
                >
                  <div
                    className="border w-12 h-12 rounded-lg grid place-items-center"
                    style={{ backgroundColor: "#F3F3F3" }}
                  >
                    <span className="text-md font-bold">{idx + 1}</span>
                  </div>
                  <div className="flex-1 relative">
                    <span className="capitalize text-md block __name">
                      {name}
                    </span>
                    <span
                      className="capitalize text-sm block"
                      style={{ color: "#9A9FAD" }}
                    >
                      {description}
                    </span>
                  </div>
                  {/* <ArrowRightIcon stroke="#BBBBBB" width={12} height={8} /> */}
                  <RightArrowIcon />
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        renderComponent(formDetail, { layoutid: form.layoutid, search })
      )}
      {(options ?? []).length < 1 && form == null && (
        <EmptyRecord>
          <h2 className="font-semibold text-sm">
            No item found {search && `that matches "${search}"`}
          </h2>
        </EmptyRecord>
      )}
    </div>
  );
}

function ModuleList1({ name, options, onClick, setLoading }) {
  const [state, setState] = useState({
    module: null,
    form: null,
    formDetail: null,
  });
  const [moduleCollapse, setModuleCollapse] = useState(false);
  const theme = useContext(ThemeContext);
  const { context } = useContext(AppContext);

  console.log(options);
  useEffect(() => {
    let defaultModule = (options || [])[0];
    if (defaultModule?.options?.length == 1) {
      // setState(prev => ({...prev, module:defaultModule, form: defaultModule?.options[0]}))
      setState((prev) => ({ ...prev, module: defaultModule, form: null }));
      handleFormChange(defaultModule?.options[0]);
      return;
    }
    setState((prev) => ({ ...prev, module: defaultModule, form: null }));
  }, [name]);

  const handleModuleChange = (module) => {
    console.log("module", module);
    setState((prev) => ({ ...prev, module, form: null }));
  };

  const handleFormChange = (form) => {
    console.log(form);

    setLoading(true);
    let postData = {
      urlMenu: null,
      layoutId: form?.layoutid,
      targetId: form?.targetid,
      target: form?.target?.toUpperCase(),
      schemaId: form?.schemaid,
      txnGroup: null,
      parentDropValue: null,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((result) => {
        let formDetail = result;
        console.log("The fromdetails are", formDetail);
        setState((prev) => ({ ...prev, form, formDetail }));
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const fetchCycleDrill = (module, cycle) => {
    // setState(prev => ({...prev, cycle, cycleDrill:recordWithFormData}))
    // return;
    // if(!cycle) { return; }

    let link = `/${module.toLowerCase().replace(/\s+/g, "-")}/${cycle
      .toLowerCase()
      .replace(/\s+/g, "-")}`;

    fetch(link, {}, 1000)
      .then((res) => res.json())
      .then((res) => {
        console.log("cycle drill ", res);
        if (res instanceof Object) {
          // setCycleDrill(prev => ({...prev, ...drill}));
          // setState(prev => ({...prev, cycle, cycleDrill:res}))
        }
      });
  };

  return (
    <div className="lg:flex h-full overflow-auto">
      <>
        {!moduleCollapse && (
          <div
            className={classNames(
              "lg:overflow-auto lg:h-full overflow-auto relative",
              { "w-24 h-full overflow-auto": moduleCollapse },
              { "lg:max-w-xs flex-1": !moduleCollapse }
            )}
            style={{ backgroundColor: "#F4F7FD" }}
          >
            <header className="uppercase text-sm flex lg:h-24 h-16 pl-8 items-center font-bold border-b mb-8">
              {name}
            </header>

            <ul className="flex w-rb lg:block lg:w-full overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200">
              {console.log("the options are", options)}
              {options &&
                options.map((item, idx) => {
                  let { name } = item;
                  let active = state?.module?.name == name;
                  return (
                    <li
                      className={classNames(
                        "flex-1 mb-4 cursor-pointer flex items-center py-3 px-8"
                      )}
                      key={idx}
                      onClick={() => handleModuleChange(item)}
                      style={{
                        color: active ? theme?.colors?.primary : "#222222",
                        background: active
                          ? "linear-gradient(270deg, #FFFFFF 22.39%, #F4F7FD 70.75%), #FFFFFF"
                          : "transparent",
                      }}
                    >
                      <span className="capitalize text-md flex-auto flex-shrink-0 font-medium pr-4 sm:pr-0">
                        {name}
                      </span>
                      <div
                        className="shadow-1 w-5 h-5 grid place-items-center rounded-xl"
                        style={{
                          backgroundColor: active
                            ? theme?.colors?.accent
                            : "#DDDDDD",
                        }}
                      >
                        <ChevronRightIcon
                          width={15}
                          height={15}
                          stroke={active ? theme?.colors?.primary : "#aaaaaa"}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </>

      <div className="flex-1 relative">
        <>
          <ModuleArrowToggler
            className="group z-10 rounded-r-full p-4 hidden items-center lg:inline-grid transform absolute bottom-16 -left-1 translate-x-1 translate-y-5 place-items-center cursor-pointer hover:scale-110 hover:translate-x-1 transition-transform"
            onClick={() => setModuleCollapse(!moduleCollapse)}
          >
        <ChevronLeftIcon
          width={24}
          height={24}
          className={classNames(
            "transition-transform -ml-3 -mt-3",
            { "rotate-180": moduleCollapse, "rotate-0": !moduleCollapse }
          )}
        />
          </ModuleArrowToggler>
          <FormList
            {...state}
            handleFormChange={handleFormChange}
            handleModuleChange={handleModuleChange}
          />
        </>
      </div>
    </div>
  );
}

export default WithLoader(ModuleList1);
