import * as React from "react";

const ComputerIcon = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 29.708 29.708"
    {...props}
  >
    <g>
      <g>
        <path
          d="M12.819,10.603L10.4,23.298h-0.002L0.174,24.812l13.642,4.896l12.146-1.632l3.746-15.816L12.819,10.603z M10.42,27.798
			l-3.349-1.28l1.615-0.215l3.757,1.256L10.42,27.798z M24.853,26.748l-13.512-3.823l0.704-3.635
			c0.014,0.021,0.021,0.04,0.036,0.062c0.16,0.227,0.389,0.455,0.692,0.691c0.329,0.256,0.637,0.426,0.923,0.508
			c0.286,0.085,0.544,0.112,0.773,0.085c0.229-0.024,0.428-0.092,0.595-0.2c0.167-0.105,0.299-0.22,0.395-0.344
			c0.118-0.15,0.198-0.292,0.246-0.419c0.048-0.129,0.073-0.246,0.077-0.351c0.005-0.106-0.008-0.2-0.037-0.281
			c-0.03-0.083-0.06-0.152-0.093-0.212c-0.032-0.06-0.062-0.104-0.087-0.14c-0.026-0.032-0.038-0.054-0.032-0.062l0.009-0.012
			c0.003-0.005,0.039,0.01,0.103,0.042c0.065,0.031,0.152,0.047,0.26,0.045c0.108-0.004,0.235-0.041,0.38-0.111
			c0.146-0.07,0.302-0.212,0.467-0.426c0.065-0.083,0.12-0.206,0.16-0.368c0.041-0.159,0.044-0.342,0.005-0.546
			c-0.035-0.203-0.125-0.42-0.267-0.65c-0.141-0.231-0.357-0.463-0.65-0.688c-0.229-0.179-0.466-0.317-0.711-0.418
			s-0.489-0.156-0.731-0.164c-0.245-0.005-0.48,0.046-0.705,0.149c-0.225,0.105-0.433,0.276-0.623,0.515l1.092,0.85
			c0.063-0.088,0.132-0.151,0.21-0.19c0.076-0.039,0.157-0.062,0.241-0.064c0.083-0.004,0.168,0.012,0.254,0.045
			c0.087,0.032,0.168,0.079,0.246,0.139c0.058,0.047,0.114,0.101,0.167,0.164c0.054,0.062,0.095,0.129,0.124,0.194
			c0.028,0.065,0.041,0.136,0.039,0.207c-0.004,0.072-0.029,0.14-0.078,0.202c-0.061,0.076-0.126,0.126-0.198,0.146
			c-0.073,0.022-0.147,0.024-0.222,0.01c-0.076-0.014-0.148-0.043-0.218-0.086c-0.07-0.043-0.137-0.086-0.198-0.135
			c-0.078-0.062-0.14-0.115-0.188-0.16c-0.047-0.049-0.092-0.088-0.131-0.125l-0.614,0.789c0.049,0.024,0.1,0.053,0.152,0.086
			c0.053,0.031,0.121,0.08,0.207,0.146c0.065,0.051,0.127,0.107,0.186,0.168c0.059,0.062,0.101,0.127,0.128,0.197
			c0.025,0.07,0.032,0.146,0.021,0.222c-0.01,0.079-0.05,0.162-0.12,0.253c-0.13,0.166-0.27,0.244-0.419,0.237
			c-0.149-0.008-0.308-0.075-0.475-0.206c-0.101-0.078-0.183-0.163-0.244-0.256c-0.063-0.09-0.094-0.189-0.098-0.299
			c-0.03-0.192,0.042-0.396,0.219-0.605l-0.871-0.677l1.071-5.527l14.494,1.703L24.853,26.748z"
        />
        <path
          d="M3.922,18.997l0.96,0.565l0.639-1.088l2.676,1.571l0.814-1.388L4.369,15.93l-0.643,1.094
			c0.185,0.121,0.318,0.262,0.401,0.422c0.084,0.158,0.126,0.326,0.128,0.5c0.002,0.176-0.027,0.353-0.088,0.531
			C4.107,18.657,4.025,18.831,3.922,18.997z"
        />
        <path
          d="M20.04,17.99l3.883-2.799l-0.658-0.914c-0.165,0.106-0.327,0.167-0.486,0.177c-0.16,0.011-0.312-0.015-0.453-0.076
			c-0.144-0.061-0.276-0.147-0.401-0.262c-0.124-0.113-0.234-0.243-0.333-0.386l-0.804,0.578l0.654,0.908l-2.236,1.611L20.04,17.99z
			"
        />
        <path
          d="M14.152,8.982c-0.029-0.106-0.035-0.204-0.016-0.292c0.02-0.087,0.06-0.166,0.115-0.232
			c0.057-0.068,0.125-0.127,0.207-0.175c0.082-0.049,0.171-0.089,0.264-0.117l-0.183-0.53l-0.6,0.206L13.43,6.366L12.667,6.63
			l0.883,2.562L14.152,8.982z"
        />
        <path
          d="M2.206,21.832c0.12,0.023,0.237,0.036,0.351,0.037c0.114,0.001,0.222-0.018,0.325-0.053
			c0.103-0.036,0.195-0.107,0.278-0.213c0.067-0.088,0.111-0.174,0.131-0.266c0.019-0.091,0.017-0.182-0.01-0.271
			c-0.025-0.09-0.071-0.176-0.137-0.262c-0.066-0.087-0.148-0.169-0.246-0.246c-0.111-0.088-0.223-0.149-0.337-0.188
			c-0.113-0.039-0.224-0.052-0.334-0.04c-0.108,0.013-0.213,0.051-0.313,0.113c-0.099,0.066-0.19,0.156-0.273,0.274l0.444,0.351
			c0.075-0.104,0.157-0.172,0.242-0.203c0.087-0.033,0.176-0.014,0.27,0.062c0.056,0.044,0.091,0.097,0.107,0.154
			c0.015,0.062-0.007,0.128-0.066,0.203c-0.023,0.028-0.053,0.051-0.086,0.062c-0.033,0.014-0.068,0.021-0.106,0.021
			c-0.038,0-0.076,0-0.114-0.007c-0.038-0.004-0.072-0.008-0.104-0.012c-0.076-0.01-0.155-0.021-0.234-0.031
			c-0.082-0.012-0.161-0.021-0.24-0.023c-0.078-0.005-0.156-0.005-0.233,0c-0.077,0.006-0.151,0.02-0.224,0.038
			c-0.084,0.024-0.164,0.062-0.238,0.11c-0.074,0.051-0.142,0.119-0.2,0.201l1.423,1.123l0.316-0.4l-0.744-0.588l0.003-0.002
			C1.97,21.79,2.088,21.809,2.206,21.832z"
        />
        <path
          d="M26.16,4.103l0.707-0.39c-0.094-0.156-0.139-0.303-0.135-0.435c0.006-0.131,0.082-0.237,0.231-0.318
			c0.088-0.05,0.176-0.067,0.264-0.056c0.087,0.013,0.163,0.079,0.229,0.199c0.025,0.049,0.038,0.097,0.035,0.148
			s-0.015,0.101-0.035,0.15c-0.02,0.05-0.043,0.1-0.067,0.147c-0.031,0.047-0.056,0.091-0.076,0.129
			C27.255,3.773,27.2,3.87,27.14,3.969c-0.061,0.102-0.115,0.201-0.164,0.304c-0.051,0.1-0.095,0.204-0.13,0.308
			c-0.034,0.104-0.061,0.211-0.074,0.315c-0.016,0.124-0.013,0.25,0.014,0.375c0.026,0.126,0.077,0.252,0.156,0.377l2.269-1.252
			l-0.354-0.639L27.67,4.411V4.407c0.081-0.143,0.17-0.287,0.267-0.431c0.099-0.146,0.18-0.291,0.244-0.441
			c0.064-0.147,0.103-0.3,0.11-0.456c0.01-0.153-0.031-0.316-0.124-0.483c-0.077-0.14-0.17-0.245-0.277-0.322
			c-0.108-0.074-0.229-0.121-0.359-0.138c-0.132-0.015-0.273-0.002-0.424,0.035c-0.15,0.038-0.305,0.101-0.462,0.188
			c-0.175,0.098-0.318,0.21-0.435,0.34c-0.113,0.127-0.193,0.266-0.237,0.417c-0.043,0.151-0.052,0.309-0.021,0.476
			C25.981,3.757,26.048,3.928,26.16,4.103z"
        />
        <path
          d="M0.569,10.523c0.144,0.151,0.292,0.262,0.441,0.325c0.148,0.066,0.293,0.094,0.432,0.089
			c0.141-0.006,0.263-0.03,0.364-0.07c0.125-0.05,0.224-0.106,0.299-0.169c0.074-0.062,0.132-0.123,0.17-0.185
			c0.041-0.063,0.066-0.126,0.078-0.186c0.012-0.061,0.017-0.115,0.018-0.161c0.002-0.05,0-0.088-0.003-0.116
			c-0.005-0.029-0.004-0.047,0.002-0.049l0.01-0.004c0.003-0.002,0.021,0.021,0.047,0.063c0.029,0.041,0.076,0.08,0.143,0.118
			c0.067,0.037,0.158,0.058,0.271,0.066c0.113,0.01,0.259-0.022,0.437-0.094c0.07-0.028,0.146-0.085,0.228-0.168
			c0.08-0.081,0.147-0.192,0.195-0.331c0.051-0.136,0.074-0.3,0.069-0.491C3.767,8.971,3.714,8.752,3.616,8.509
			C3.54,8.32,3.444,8.151,3.331,8.003C3.217,7.854,3.088,7.735,2.942,7.644C2.795,7.555,2.632,7.502,2.46,7.487
			C2.285,7.471,2.098,7.501,1.897,7.579l0.365,0.904c0.07-0.032,0.135-0.045,0.197-0.043C2.52,8.443,2.575,8.46,2.628,8.487
			c0.052,0.027,0.099,0.066,0.14,0.117c0.04,0.051,0.074,0.107,0.1,0.172c0.019,0.047,0.033,0.102,0.044,0.16
			C2.922,8.993,2.924,9.047,2.919,9.1c-0.008,0.05-0.022,0.096-0.05,0.138C2.84,9.281,2.802,9.313,2.75,9.335
			C2.686,9.359,2.629,9.366,2.577,9.354c-0.052-0.012-0.1-0.037-0.14-0.073C2.397,9.243,2.364,9.202,2.336,9.15
			C2.307,9.1,2.282,9.048,2.263,8.998C2.235,8.934,2.217,8.879,2.205,8.835C2.193,8.789,2.18,8.748,2.169,8.71L1.515,8.975
			c0.021,0.033,0.041,0.068,0.063,0.106c0.02,0.039,0.045,0.092,0.073,0.164C1.672,9.301,1.69,9.354,1.705,9.413
			C1.719,9.47,1.723,9.526,1.713,9.578c-0.008,0.053-0.031,0.1-0.066,0.145c-0.033,0.044-0.088,0.08-0.161,0.111
			C1.348,9.888,1.234,9.887,1.145,9.831C1.057,9.77,0.985,9.675,0.928,9.536C0.895,9.452,0.875,9.371,0.87,9.293
			c-0.006-0.077,0.011-0.15,0.046-0.218c0.05-0.128,0.165-0.225,0.349-0.29L0.897,7.871c-0.044,0.013-0.104,0.036-0.185,0.07
			c-0.08,0.036-0.163,0.084-0.251,0.15c-0.086,0.068-0.168,0.15-0.246,0.251c-0.077,0.1-0.135,0.223-0.172,0.367
			C0.002,8.854-0.008,9.023,0.008,9.217c0.018,0.196,0.076,0.418,0.178,0.67C0.295,10.16,0.423,10.372,0.569,10.523z"
        />
        <path
          d="M13.175,2.715c0.068,0.097,0.167,0.195,0.298,0.297c0.141,0.11,0.272,0.184,0.396,0.219
			c0.123,0.036,0.234,0.048,0.332,0.036c0.099-0.011,0.184-0.039,0.255-0.086c0.072-0.046,0.13-0.095,0.171-0.147
			c0.05-0.065,0.084-0.126,0.104-0.181s0.032-0.105,0.034-0.149c0.003-0.046-0.005-0.087-0.017-0.122
			c-0.013-0.035-0.026-0.065-0.04-0.091c-0.014-0.026-0.025-0.044-0.037-0.059c-0.012-0.015-0.018-0.023-0.015-0.028L14.661,2.4
			c0.002-0.001,0.017,0.004,0.044,0.019c0.028,0.014,0.065,0.02,0.112,0.02c0.046-0.001,0.101-0.018,0.163-0.048
			c0.062-0.03,0.129-0.092,0.2-0.184c0.028-0.035,0.051-0.089,0.069-0.158c0.018-0.069,0.019-0.146,0.001-0.234
			c-0.015-0.089-0.053-0.181-0.113-0.279c-0.061-0.101-0.154-0.199-0.28-0.297c-0.099-0.076-0.201-0.136-0.306-0.18
			s-0.211-0.067-0.313-0.07c-0.105-0.002-0.208,0.021-0.304,0.063c-0.096,0.045-0.187,0.12-0.268,0.222l0.469,0.364
			c0.027-0.037,0.058-0.064,0.091-0.082c0.033-0.017,0.066-0.025,0.104-0.028c0.036-0.001,0.071,0.006,0.109,0.019
			c0.037,0.016,0.071,0.035,0.105,0.063c0.024,0.018,0.049,0.041,0.071,0.069c0.022,0.027,0.04,0.055,0.055,0.084
			c0.012,0.028,0.018,0.058,0.016,0.089c-0.002,0.03-0.014,0.06-0.033,0.086c-0.026,0.033-0.055,0.055-0.085,0.064
			c-0.031,0.008-0.063,0.01-0.097,0.004C14.439,2,14.408,1.987,14.378,1.97c-0.031-0.019-0.059-0.038-0.086-0.06
			c-0.033-0.025-0.059-0.048-0.08-0.068c-0.02-0.021-0.04-0.037-0.057-0.054l-0.264,0.339c0.021,0.011,0.042,0.023,0.065,0.037
			c0.022,0.015,0.053,0.035,0.089,0.063c0.027,0.021,0.056,0.046,0.079,0.073c0.025,0.025,0.045,0.054,0.055,0.084
			c0.013,0.029,0.016,0.062,0.01,0.095c-0.004,0.034-0.021,0.07-0.052,0.109c-0.056,0.072-0.114,0.104-0.179,0.103
			c-0.064-0.004-0.134-0.032-0.204-0.089c-0.044-0.033-0.079-0.07-0.104-0.11c-0.028-0.04-0.042-0.082-0.043-0.129
			c-0.013-0.082,0.018-0.169,0.094-0.259l-0.473-0.37c-0.02,0.021-0.044,0.052-0.077,0.094c-0.03,0.044-0.059,0.095-0.085,0.156
			c-0.024,0.062-0.043,0.131-0.053,0.207c-0.01,0.076-0.002,0.158,0.021,0.248C13.061,2.524,13.106,2.616,13.175,2.715z"
        />
        <path
          d="M16.856,6.789l3.736,0.528l1.227-0.784l-1.29-2.013l0.9-0.576l-0.868-1.356l-0.898,0.577L19.27,2.552l-1.109,0.711
			l0.394,0.611l-2.528,1.617L16.856,6.789z M19.421,5.229l0.71,1.108l-1.979-0.294L19.421,5.229z"
        />
        <path
          d="M10.294,11.802l-1.835-0.426l0.191-0.819L7.415,10.27l-0.188,0.819L6.668,10.96l-0.235,1.008l0.558,0.129L6.455,14.4
			l1.182,0.274l2.398-1.757L10.294,11.802z M7.957,13.541l0.268-1.157l1.011,0.234L7.957,13.541z"
        />
        <path
          d="M23.697,20.396l-2.816-0.07l-0.844,0.688l1.132,1.385l-0.617,0.504l0.761,0.931l0.618-0.504l0.344,0.42l0.76-0.621
			l-0.343-0.422l1.734-1.418L23.697,20.396z M21.93,21.775l-0.623-0.762l1.494,0.049L21.93,21.775z"
        />
        <path
          d="M3.248,5.935c0.191,0.273,0.45,0.552,0.779,0.836C4.41,7.104,4.794,7.352,5.176,7.518
			c0.382,0.164,0.747,0.253,1.095,0.261c0.347,0.009,0.673-0.057,0.978-0.197c0.307-0.139,0.576-0.346,0.812-0.617
			c0.27-0.313,0.461-0.625,0.567-0.937c0.107-0.31,0.145-0.607,0.113-0.891c-0.032-0.284-0.124-0.554-0.274-0.81
			C8.316,4.07,8.122,3.839,7.886,3.635C7.687,3.463,7.464,3.308,7.219,3.171C6.973,3.034,6.713,2.957,6.443,2.938l-0.001-0.03
			l0.767-0.573l2.493,2.16l1.134-1.31L7.157,0L3.93,2.606l1.431,1.238c0.19-0.05,0.37-0.047,0.545,0.01
			c0.175,0.055,0.353,0.163,0.533,0.318c0.229,0.2,0.358,0.427,0.387,0.679c0.029,0.253-0.06,0.499-0.265,0.734
			C6.374,5.8,6.145,5.905,5.874,5.897c-0.271-0.005-0.52-0.105-0.744-0.3c-0.118-0.101-0.216-0.211-0.298-0.33
			c-0.08-0.119-0.131-0.242-0.154-0.371C4.641,4.713,4.672,4.532,4.771,4.357L3.247,3.038C3.161,3.115,3.076,3.224,2.991,3.362
			C2.908,3.5,2.839,3.661,2.783,3.842C2.73,4.026,2.703,4.226,2.705,4.446c0.001,0.221,0.045,0.457,0.13,0.706
			S3.057,5.661,3.248,5.935z"
        />
        <path
          d="M23.105,9.235c0.123,0.109,0.255,0.186,0.394,0.225c0.141,0.041,0.283,0.049,0.434,0.029
			c0.147-0.021,0.294-0.066,0.436-0.139c0.118-0.06,0.234-0.134,0.345-0.223c0.111-0.088,0.198-0.193,0.261-0.316l0.014,0.004
			l0.12,0.471l-1.491,0.747l0.393,0.783l2.2-1.104L25.615,7.7l-0.858,0.43c-0.013,0.1-0.05,0.182-0.108,0.253
			c-0.062,0.072-0.146,0.133-0.253,0.189c-0.138,0.067-0.269,0.084-0.393,0.048c-0.123-0.035-0.221-0.123-0.292-0.266
			c-0.063-0.129-0.068-0.256-0.014-0.382s0.15-0.223,0.285-0.29c0.069-0.035,0.141-0.059,0.212-0.073
			c0.071-0.016,0.138-0.016,0.203-0.002c0.093,0.019,0.171,0.067,0.233,0.147l0.911-0.456c-0.021-0.056-0.054-0.116-0.103-0.184
			c-0.05-0.065-0.109-0.129-0.185-0.189c-0.074-0.061-0.164-0.11-0.269-0.154c-0.104-0.042-0.222-0.066-0.354-0.074
			c-0.133-0.009-0.281,0.005-0.445,0.041c-0.165,0.036-0.346,0.104-0.542,0.202c-0.229,0.116-0.419,0.248-0.569,0.395
			c-0.153,0.146-0.264,0.3-0.333,0.461c-0.072,0.161-0.104,0.325-0.1,0.495c0.006,0.169,0.052,0.335,0.132,0.498
			C22.871,8.977,22.98,9.125,23.105,9.235z"
        />
      </g>
    </g>
  </svg>
);

export default ComputerIcon;
