import React, { useState } from 'react';
import ConfirmDialog from './ConfirmDialog';

function ConfirmButton({children,onClick:handleClick,action,...rest}) {

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subtitle: "",
      });

    return (
        <>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
         <button
            onClick={e => {
                setConfirmDialog({
                    isOpen: true,
                    title:
                      `Are you sure you want to ${action}?`,
                    subtitle: "You can't undo this operation",
                    onConfirm: handleClick,
                  })
            }}
            {...rest}>
            {children}
        </button>
        </>
       
    );
}

export default ConfirmButton;