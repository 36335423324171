import classNames from 'classnames';
import React from 'react';

function RecordWithFormWrapper({children,formHidden}) {

    console.log("props from record with form wrapper", {children,formHidden})
    return (
        <div className={classNames("record-with-form-wrapper", {"show-form": formHidden === false})} >
            {children}
        </div>
    );
}

export default RecordWithFormWrapper;