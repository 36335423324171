import React, { useEffect, useState } from 'react';

function OtpTimeout({onCompleted, resend}) {

    const [minutes,setMinutes] = useState(0)
    const [seconds,setSeconds] = useState(0)

    function countdown( elementName, minutes, seconds )
    {
        var element, endTime, hours, mins, msLeft, time;
        function twoDigits( n )
        {
            return (n <= 9 ? "0" + n : n);
        }
        function updateTimer()
        {
            msLeft = endTime - (+new Date);
            if ( msLeft < 1000 ) {
                onCompleted()
                //element.innerHTML = "Time is up!";
            } else {
                time = new Date( msLeft );
                hours = time.getUTCHours();
                mins = time.getUTCMinutes();
                element.innerHTML = (hours ? hours + ':' + twoDigits( mins ) : mins) + ':' + twoDigits( time.getUTCSeconds() );
                setTimeout( updateTimer, time.getUTCMilliseconds() + 500 )
            }
        }
        element = document.getElementById( elementName );
        endTime = (+new Date) + 1000 * (60*minutes + seconds) + 500;
        updateTimer();
    }
    
    useEffect(() => {
        countdown("timer-countdown", 1, 0 );
    },[resend])


    return (
        <div>
            <div class="otp-countdown" id="timer-countdown" dangerouslySetInnerHTML={{__html: "01:00"}}></div>
        </div>
    );
}

export default OtpTimeout;