import React from "react";
import uuid from "react-uuid";
import component from "./components";

const nonAutoRenders = [
  "ParentMerge3MiddleArea",
  "ChartWrapperControlm12",
  "ChartWrapperControlm22",
  "ChartWrapperControlm32",
];
const nonAutoRenderSet = new Set(nonAutoRenders);

function renderComponent(config, additionalProps) {
  const { type, subcomponents, ...otherProps } = config ?? {};
  if (typeof component[type] !== "undefined") {
    console.log("renderComponent", config.type, config, additionalProps, subcomponents);
    additionalProps ??= {};

    console.log("nonAutoRenders[type]", nonAutoRenders[type]);
    if (nonAutoRenderSet.has(type)) {
      return React.createElement(component[type], {
        type,
        ...otherProps,
        ...additionalProps,
        subcomponents,
      });
    }

    return React.createElement(
      component[type],
      { type, subcomponents, ...otherProps, ...additionalProps },
      subcomponents &&
        (typeof subcomponents === "string"
          ? subcomponents
          : subcomponents.map((c, idx) => (
              <React.Fragment key={idx}>{renderComponent(c)}</React.Fragment>
            )))
    );
  }
}

// function renderComponent(config,additionalProps) {
//   const {type, ...otherProps} = config ?? {}
//   if (typeof component[type] !== "undefined") {
//     console.log("renderComponent",config)
//     return React.createElement(
//         component[type],
//         {type,
//           ...otherProps,
//           ...additionalProps},
//         subcomponents &&
//           (typeof subcomponents === "string"
//             ? subcomponents
//             : subcomponents.map((c, idx) => <React.Fragment key={idx}>{renderComponent(c)}</React.Fragment>))
//       );
//   }
// }

export default renderComponent;
