import React, { Component } from 'react';
import api from "../endpoints"
import renderComponent from "../renderComponent";
import classNames from 'classnames';
import uuid from 'react-uuid';

class ModuleLayout1 extends Component {

    constructor(props) {
        super(props);
      }
    componentDidMount(){

    }

    layoutRule = [
        "max-w-xs overflow-auto", 
        "",
        "max-w-sm"
    ]

    render() {
       console.log("this.state.config",this.props);
       return (
            <div className="home-layout-1 flex w-full h-full absolute -mx-12">
                {this.props.subcomponents.map((c,idx) => (
                    <section key={idx} className={classNames(this.layoutRule[idx],"right-sidebar flex-1")}>
                        {renderComponent(c)}
                    </section>
                    ))
                }
            </div>
       )
    }
}

export default ModuleLayout1;