import * as React from "react";

const BellIcon = (props) => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.01003 1.45846L3.71916 0.138462C1.55266 1.82769 0.126379 4.43077 0 7.38462H1.80542C1.86936 6.21398 2.19072 5.07317 2.74556 4.04713C3.3004 3.02108 4.07443 2.13623 5.01003 1.45846V1.45846ZM16.1946 7.38462H18C17.8646 4.43077 16.4383 1.82769 14.2808 0.138462L12.999 1.45846C13.9306 2.13957 14.7012 3.02539 15.2542 4.05089C15.8071 5.07638 16.1285 6.21551 16.1946 7.38462ZM14.4162 7.84615C14.4162 5.01231 12.9358 2.64 10.3541 2.01231V1.38462C10.3541 0.618462 9.74925 0 9 0C8.25075 0 7.64594 0.618462 7.64594 1.38462V2.01231C5.05517 2.64 3.58375 5.00308 3.58375 7.84615V12.4615L1.77834 14.3077V15.2308H16.2217V14.3077L14.4162 12.4615V7.84615ZM9 18C9.12638 18 9.24373 17.9908 9.36108 17.9631C9.94784 17.8338 10.4263 17.4277 10.661 16.8738C10.7513 16.6523 10.7964 16.4123 10.7964 16.1538H7.18556C7.19458 17.1692 7.99799 18 9 18Z"
      fill="inherit"
    />
  </svg>
);

export default BellIcon;