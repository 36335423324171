import React, {useContext,useEffect,useState}  from 'react';
import AppContext from '../contexts/AppContext';


function useMenuUpdate() {
    const {context,setContext} = useContext(AppContext)
    const [menu,setMenu] = useState(null)

    const updateMenu = (menuUpdate = []) => setMenu(menuUpdate)

    useEffect(() => {
        console.log("updating menu",menu)
        if(!menu) return;
        let badgeCounts = {};
        menu?.filter(m => m.BADGE_COUNT !== undefined)
        ?.forEach(m => {
            badgeCounts[m["NAME"].toLowerCase()] = m["BADGE_COUNT"];
        })
        console.log("badgeCounts",badgeCounts);
        
        let pageConfig = {...context.pageConfig};
        console.log("pageConfig",pageConfig);
        pageConfig?.subcomponents[0]?.subcomponents[0].subcomponents[1].options
        ?.forEach(option => {
            if (badgeCounts[option.name.toLowerCase()] !== undefined){
                console.log("with badge", option.name)
                option["badge"] = badgeCounts[option.name.toLowerCase()]
            }
        })
        setContext(prev => ({...prev, pageConfig}));
    },[menu])

    return {updateMenu}
}

export default useMenuUpdate;