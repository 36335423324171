import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ScreenOverlay } from './ScreenLoader';

export const WithOverlay = WrappedComponent => {
    function HOC(props){
        const [useOverlay,setUseOverlay] = useState(false)
        
        return (
            <>
                {useOverlay && <ScreenOverlay style={{backgroundColor:'transparent'}}/>}
                <WrappedComponent {...props} setOverlay={setUseOverlay}  />
            </>
        );
    }

    return HOC;
}


export default WithOverlay;