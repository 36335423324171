import React from 'react';

function MainOpenArea1({children}) {
    return (
        <div className="pt-14 w-rb px-6 h-full lg:pt-12 overflow-hidden">
            <div className="block h-full">
                {children}
            </div>
        </div>
    );
}

export default MainOpenArea1;