import {
  ArrowsExpandIcon,
  ChevronDownIcon,
  ArrowDownIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import CloseIcon from "../icons/CloseIcon";
import Spinner from "../loaders/Spinner";
import { appendQueryToUri } from "../util/generalUtil";
import TransformValue from "./TransformValue";
import classNames from "classnames";
import ReactPaginate from "react-paginate";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const invalidHeads = [
  "drillable",
  "drillid",
  "ID",
  "target_name",
  "target_type",
];

const DataRowDrillButton = ({ drill, setDrill, loadDrill }) => {
  const theme = useTheme();

  const saveToPdf = () => {
    var dom = document.getElementById("print");
    let { height, width } = dom.getBoundingClientRect();
    height += 283;
    html2canvas(dom, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [9.2, height / 96],
        putOnlyUsedFonts: true,
      });
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("Report.pdf");
    });
  };

  //download
  const saveToPdf2 = () => {
    //converting to pdf for download
    var dom = document.getElementById("print");

    //converting the doc to svg image file
    toPng(dom)
      .then((dataUrl) => {
        const img = new Image(); //img constructor
        img.crossOrigin = "Anonymous";
        img.src = dataUrl;
        img.onload = () => {
          //initialize pdf document here
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            //format: [5.5, 8.5]
            format: "a4",
            putOnlyUsedFonts: true,
          });

          const imageProps = pdf.getImageProperties(img);
          const imgType = imageProps.fileType;
          const pdfWidth = pdf.internal.pageSize.getWidth();

          //calculate number of pages

          const pxFullHeight = imageProps.height;
          const pxPageHeight = Math.floor((imageProps.width * 8.5) / 5.5);
          const nPages = Math.ceil(pxFullHeight / pxPageHeight);

          //Define page height seperately so it can be trimmed
          let pageHeight = pdf.internal.pageSize.getHeight();

          //create page canvass to split up the full image
          const pageCanvas = document.createElement("canvas");
          const pageContext = pageCanvas.getContext("2d");

          pageCanvas.width = imageProps.width;
          pageCanvas.height = pxPageHeight;

          for (let page = 0; page < nPages; page++) {
            //trim the final page to reduce the file size.
            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight = (pageCanvas.height * pdfWidth) / pageCanvas.width;
            }

            //display page
            const w = pageCanvas.width;
            const h = pageCanvas.height;
            pageContext.fillStyle = "white";
            pageContext.fillRect(0, 0, w, h); // Rect = rectangle
            pageContext.drawImage(
              img,
              0,
              page * pxPageHeight,
              w,
              h,
              0,
              0,
              w,
              h
            );

            //add page
            if (page) pdf.addPage();

            const imgData = pageCanvas.toDataURL(`image/${imgType}`, 1);
            pdf.addImage(imgData, imgType, 0, 0, pdfWidth, pageHeight);
          }
          // save it
          pdf.save("Report.pdf");
        };
      })
      .catch((error) => {
        throw error;
        alert("oops!, could not download table", error);
        console.log("oops!, could not download table", error);
      });
  };

  return (
    <div className="inline-flex gap-4">
      <button
        className="inline-flex items-center justify-center w-7 h-7 rounded-md bg-background-yellow shadow hover:text-main-black"
        onClick={(e) => (drill ? setDrill(false) : loadDrill())}
      >
        {drill ? (
          <CloseIcon fill={theme?.colors?.primary} width={10} />
        ) : (
          <ArrowsExpandIcon fill={theme?.colors?.primary} width={18} />
        )}
      </button>

      <button
        className="inline-flex items-center justify-center w-7 h-7 rounded-md bg-background-yellow shadow hover:text-main-black "
        onClick={(e) => saveToPdf()}
      >
        {<ArrowDownIcon fill={theme?.colors?.primary} width={18} />}
      </button>
    </div>
  );
};

const DataRow1 = ({
  tHeads,
  row,
  drillable,
  headerProps,
  activePeriod,
  dateFilter,
}) => {
  const [drill, setDrill] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const gridType = drill?.gridtype || "horizontal";

  const loadDrill = (item) => {
    item = { ...item, ...(headerProps || {}) };

    //use closure to store item.
    return () => {
      setLoadingData(true);
      let postData = {
        urlMenu: null,
        layoutId: item?.layoutid,
        targetId: item?.targetid,
        target: item?.target,
        schemaId: activePeriod?.ID,
        txnGroup: null,
        parentDropValue: null,
        drillId: item.drillid?.toString(),
        targetName: item?.target_name,
        targetType: item?.target_type,
        dateFilter: JSON.stringify(dateFilter) ?? null,
      };
      console.log("the item to drill postData", postData);
      appFetch
        .get(appendQueryToUri(apiEndpoints.menuView, postData))
        .then((res) => {
          let options = (res.options ?? [])[0] ?? null;
          // console.log(options);
          console.log("the drill item options", res);
          setDrill(options);
        })
        .catch((e) => console.log(e.message))
        .finally(() => setLoadingData(false));
    };
  };

  // const employeeDetails= {
  //     'name':'Olajumoke Omowumi Daramola',
  //     'department':'Safety',
  //     'designation': 'Quality and Safety Supervisor'
  // }

  // console.log(employeeDetails)
  // console.log(drill);

  let logo = null;
  let title = drill?.Title ?? "";
  try {
    let logoList = JSON.parse(drill?.logo);
    logo = logoList[0];
    logo = logo.replace("http", "https");
  } catch {}

  return (
    <>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        {tHeads?.map((s, idx) => (
          <td className="px-4 py-4 text-xs" key={idx}>
            <TransformValue value={row[s]} />
          </td>
        ))}
        {drillable && (
          <td class="px-4 py-4">
            <DataRowDrillButton
              drill={drill}
              setDrill={setDrill}
              loadDrill={loadDrill(row)}
            />
          </td>
        )}
      </tr>
      {loadingData && (
        <tr>
          <td colSpan={tHeads.length}>
            <div className="grid place-items-center py-6">
              <Spinner fill="#e2e3e4" width={42} height={42} />
            </div>
          </td>
        </tr>
      )}
      {drill && (
        <tr>
          <td colSpan={tHeads.length / 2}>
            {gridType === "horizontal" &&
              (() => {
                const drillKeys = Object.keys(
                  (drill?.grid ?? [])[0] ?? {}
                ).filter((key) => !invalidHeads.includes(key));

                return (
                  <div class="p-6 bg-gray-100 w-full">
                    <div
                      class="relative overflow-x-auto  sm:rounded-lg"
                      id="print"
                    >
                      <table className="w-full border-2 mb-6 dark:text-gray-400">
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="pl-4">
                                          {logo && (
                                            <img
                                              className="h-20 object-contain"
                                              src={logo}
                                            ></img>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="mt-6 mb-4 pl-4 mb-6 dark:text-gray-600">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: title,
                                            }}
                                          ></div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            {drillKeys?.map((str, idx) => (
                              <th
                                scope="col"
                                class="px-4 py-3 text-xs"
                                key={idx}
                              >
                                {str}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {drill?.grid?.map((line, key) => {
                            return (
                              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                {drillKeys?.map((str, idx) => (
                                  <td class="px-4 py-4 text-xs">
                                    <TransformValue value={line[str]} />
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                          {drill?.grid?.length === 0 && (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td class="px-4 py-4 text-xs">
                                <span className="flex justify-center">
                                  No record available.
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })()}

            {gridType === "vertical" &&
              (() => {
                const objKeys = Object.keys(
                  (drill?.grid ?? [])[0] ?? {}
                ).filter((key) => !invalidHeads.includes(key));
                return (
                  <div class="p-6 w-full">
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                      <ul className="modal-list">
                        {drill?.grid.map((record) => (
                          <li className="modal-list-item list-none">
                            <ul className="grid gap-1 my-6 border-b border-gray-200 mb-3">
                              {objKeys.map((str, idx) => {
                                return (
                                  <li
                                    className={classNames(
                                      "flex justify-between"
                                    )}
                                    key={idx}
                                  >
                                    <div
                                      className={classNames("py-2 px-3 w-36", {
                                        "bg-gray-200": (idx + 1) % 2 != 0,
                                      })}
                                    >
                                      <span className="text-gray-700 font-semibold text-xs">
                                        {str}
                                      </span>
                                    </div>
                                    <div
                                      className={classNames(
                                        "ml-0.5 py-2 px-3 flex-grow",
                                        { "bg-gray-200": (idx + 1) % 2 != 0 }
                                      )}
                                    >
                                      <span className="text-xs">
                                        <TransformValue value={record[str]} />
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })()}
          </td>
        </tr>
      )}
    </>
  );
};

const sorts = [
  {
    id: 1,
    name: "ASC",
  },
  {
    id: 2,
    name: "DESC",
  },
];

const DataTable1 = ({
  rows,
  headers,
  setLoading,
  loading,
  setGrid,
  headerProps,
  activePeriod,
  dateFilter,
}) => {
  const orders = headerProps?.orderby
    ?.split(",")
    .map((s, idx) => ({ id: idx + 1, name: s }));

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedSort, SetSelectedSort] = useState(null);

  useEffect(() => {
    if (orders?.length) {
      setSelectedOrder(orders[0]);
      SetSelectedSort(sorts[0]);
    }
  }, [orders?.length, activePeriod?.ID]);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [search, setSearch] = useState("");
  // const [dataGrid,setDataGrid] = useState(null)
  const itemsPerPage = 40;
  let searchRegex = new RegExp(search, "i");

  let items = rows?.filter((row) =>
    searchRegex.test(Object.values(row).join(""))
  );

  useEffect(() => {
    if (search) {
      setItemOffset(0);
    }

    items = rows?.filter((row) =>
      searchRegex.test(Object.values(row).join(""))
    );

    if (itemsPerPage != 0 && !loading) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, search, loading, selectedOrder, selectedSort]);

  const sanitizedHeaders = headers?.filter((s) => !invalidHeads.includes(s));
  const drillable = rows[0]?.drillable === "YES";

  const fetchData = (orderBy, sortBy) => {
    setLoading(true);

    let postData = {
      layoutId: headerProps?.layoutid,
      schemaId: activePeriod?.ID,
      target: headerProps?.target,
      targetId: headerProps?.targetid,
      txnGroup: `${orderBy.name}-${sortBy.name}`,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let grid = (res?.options || [])[0]?.grid || [];
        setGrid(grid);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const changeSortBy = (sort) => {
    SetSelectedSort(sort);
    fetchData(selectedOrder, sort);
  };

  const changeOrderBy = (order) => {
    setSelectedOrder(order);
    fetchData(order, selectedSort);
  };

  const handleSearchWithDebounce = (() => {
    let timeoutFunc;
    let timeout = 500;

    return ({ target: { value } }) => {
      if (timeoutFunc) clearTimeout(timeoutFunc);

      timeoutFunc = setTimeout(() => setSearch(value), timeout);
    };
  })();

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="relative overflow-x-auto mt-4">
      {loading ? (
        <div className="grid place-items-center place-content-center h-20">
          <span className="text-sm text-gray-400 font-bold">Loading...</span>
          <Spinner width={32} height={32} fill="#aaaaaa" />
        </div>
      ) : (
        <>
          <div className="flex justify-end">
            <div className="flex gap-2">
              <div class="pt-2 relative text-gray-600 mb-4">
                <input
                  onChange={handleSearchWithDebounce}
                  className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                  autoComplete="off"
                  type="search"
                  name="search"
                  placeholder="Search"
                />
                <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
                  <svg
                    className="text-gray-600 h-4 w-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    width="512px"
                    height="512px"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
              {selectedOrder && (
                <div className="flex gap-2">
                  <div className="w-52">
                    <Listbox value={selectedOrder} onChange={changeOrderBy}>
                      <div className="relative mt-1">
                        <Listbox.Button className="h-10 relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">
                            {selectedOrder.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {orders?.map((order, idx) => (
                              <Listbox.Option
                                key={idx}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "bg-amber-100 text-amber-900"
                                      : "text-gray-900"
                                  }`
                                }
                                value={order}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {order.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <div className="flex gap-2">
                    <Listbox value={selectedSort} onChange={changeSortBy}>
                      <div className="relative mt-1">
                        <Listbox.Button className="h-10 relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                          <span className="block truncate">
                            Sort by: {selectedSort.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {sorts.map((sort, idx) => (
                              <Listbox.Option
                                key={idx}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "bg-amber-100 text-amber-900"
                                      : "text-gray-900"
                                  }`
                                }
                                value={sort}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {sort.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
              )}

              {/* <Listbox value={selectedOrder} onChange={setSelectedOrder}>
                    <Listbox.Button>{selectedOrder.name}</Listbox.Button>
                        <Listbox.Options>
                            {orders.map((order) => (
                            <Listbox.Option
                                key={order.id}
                                value={order}
                                className="ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black"
                                >
                                <CheckIcon className="hidden ui-selected:block" />
                                {order.name}
                            </Listbox.Option>
                            ))}
                        </Listbox.Options>
                        </Listbox> */}
            </div>
          </div>
          <div className="overflow-x-visible w-full tranx-table scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {sanitizedHeaders?.map((s, idx) => (
                    <th scope="col" className="px-4 py-3 text-xs" key={idx}>
                      {s}
                    </th>
                  ))}
                  {drillable && <td></td>}
                </tr>
              </thead>

              <tbody>
                {currentItems?.map((r) => (
                  <DataRow1
                    row={r}
                    drillable={drillable}
                    tHeads={sanitizedHeaders}
                    headerProps={headerProps}
                    activePeriod={activePeriod}
                    dateFilter={dateFilter}
                  />
                ))}

                {currentItems?.length === 0 && !loading && (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td
                      className="px-4 py-4 text-md"
                      colSpan={sanitizedHeaders.length}
                    >
                      <span className="grid place-items-center place-content-center font-bold">
                        No record available.
                      </span>
                    </td>
                  </tr>
                )}
                {/* <tr>
                   <td colSpan={sanitizedHeaders.length}>
                    <div className="flex justify-end">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                    />
                    </div>
                   </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-3">
            {items?.length > 0 && (
              <ReactPaginate
                breakLabel="..."
                previousLabel={"←"}
                nextLabel={"→"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName="inline-flex items-center -space-x-px"
                previousLinkClassName="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                nextLinkClassName="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                pageLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                breakLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                activeLinkClassName="z-10 py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DataTable1;
