import React from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";
import formatter, { formatNumber } from "../util/formatter";
import moment from "moment";

const PreviewContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  min-width: 960px;
  background: #fff;
`;

const PrintTextPlaceHolder = styled.span`
  color: #9e9e9e;
`;

const PrintLogoPlaceHolder = styled.span``;

function Preview({ name, data, handlePrint, handleSubmit, setIsOpen }) {
  // data = previewData;
  const lineOne = (data?.line ?? [])[0];
  const tableHeader = Object.keys(lineOne ?? {}).filter(
    (s) => !["line_currency", "line_remark"].includes(s)
  );
  const { header, uploads } = data;

  console.log("let us see the print data", data);

  function getCurrencySymbol(currencyCode) {
    const currencySymbols = {
      "&#8358;": "₦", // Nigerian Naira
      "&#36;": "$", // US Dollar
      // Add more currency code mappings as needed
    };

    return currencySymbols[currencyCode] || currencyCode;
  }

  function removeCommasFromString(inputString) {
    // Use the replace method with a regular expression to remove commas
    return inputString.replace(/,/g, "");
  }

  const getObjectValue = ({ value, prop }) => {
    let finalVal = null;
    // value = removeCommasFromString(value);
    switch (value?.constructor) {
      case {}.constructor:
        finalVal = value?.name;
        break;
      case [].constructor:
        finalVal = value.map((item) => item?.name);
        break;
      default:
        finalVal = value ?? "nil";
    }

    if (prop && /amount$/.test(prop)) {
      let currencyCode = (data?.line ?? [])[0]?.line_currency ?? "";
      let _currencyCode = getCurrencySymbol(currencyCode);
      console.log("finalVal ish", finalVal);
      let _finalVal = removeCommasFromString(finalVal);
      return `${_currencyCode}${formatNumber(_finalVal)}`;
    }

    if (prop && /quantity$/.test(prop)) {
      let _finalVal = removeCommasFromString(finalVal);
      let finall = formatNumber(_finalVal);

      console.log("finalVal ish", finall);

      return `${finall}`;
    }

    if (prop && /number$/.test(prop)) {
      let _finalVal = removeCommasFromString(finalVal);
      let finall = formatNumber(_finalVal);

      console.log("finalVal ish", finall);

      return `${finall}`;
    }

    return finalVal;
  };

  const getSubtotal = () => {};

  const getTotalInclusiveDiscount = () => {};

  const getTotalInclusiveTax = () => {};

  const getTax = () => {};

  return (
    <PreviewContainer>
      <div className="p-2">
        <div className="border border-color-primary rounded-md overflow-hidden">
          <div className="flex bg-gray-100 justify-between px-20 w-full py-10 mb-4">
            <div className="bg-primary p-5 flex flex-col w-60 self-start">
              <span className="text-sm text-white capitalize">status</span>
              <span className="text-lg text-white capitalize">pending</span>
            </div>
            <div className="grid  w-60 ">
              <span className="text-lg uppercase mb-3">{name}</span>
              <div className="grid gap-2">
                {/* <div className='flex flex-col'>
                                <span class="text-primary font-medium">Bid Number:</span>
                                <span class="text-gray-500">#{'NIL'}</span>
                            </div> */}
                <div className="flex flex-col">
                  <span class="text-primary font-medium">Date:</span>
                  <span class="text-gray-500">
                    {moment(data?.tender_date).format("DD MMM, YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between px-20 w-full mb-4">
            <div className="w-60 ">
              <div className="logo">
                {data?.header?.company_section_1?.logo ?? (
                  <PrintLogoPlaceHolder />
                )}
              </div>
              <div className="grid">
                <div className="mb-2 grid">
                  {data?.header?.company_section_1?.name ?? (
                    <PrintTextPlaceHolder>Company's Name</PrintTextPlaceHolder>
                  )}
                </div>
                <div className="mb-2 grid">
                  {data?.header?.company_section_1?.address ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data?.header?.company_section_1?.address,
                      }}
                    ></span>
                  ) : (
                    <PrintTextPlaceHolder>
                      Company's Address
                    </PrintTextPlaceHolder>
                  )}
                </div>
                <div className="mb-2 grid">
                  {data?.header?.company_section_1?.email ?? (
                    <PrintTextPlaceHolder>Email</PrintTextPlaceHolder>
                  )}
                </div>
                <div className="mb-2 grid">
                  {data?.header?.company_section_1?.phone_number ?? (
                    <PrintTextPlaceHolder>Phone</PrintTextPlaceHolder>
                  )}
                </div>
              </div>
            </div>
            <div className="w-60 ">
              <div className="logo">
                {data?.header?.company_section_2?.logo ?? (
                  <PrintLogoPlaceHolder />
                )}
              </div>
              <div className="grid">
                <div className="mb-2 grid">
                  {data?.header?.company_section_2?.name ?? (
                    <PrintTextPlaceHolder>Vendor's Name</PrintTextPlaceHolder>
                  )}
                </div>
                <div className="mb-2 grid">
                  {data?.header?.company_section_2?.address ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data?.header?.company_section_2?.address,
                      }}
                    ></span>
                  ) : (
                    <PrintTextPlaceHolder>
                      Vendor's Address
                    </PrintTextPlaceHolder>
                  )}
                </div>
                <div className="mb-2 grid">
                  {data?.header?.company_section_2?.email ?? (
                    <PrintTextPlaceHolder>Email</PrintTextPlaceHolder>
                  )}
                </div>
                <div className="mb-2 grid">
                  {data?.header?.company_section_2?.phone ?? (
                    <PrintTextPlaceHolder>Phone</PrintTextPlaceHolder>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-400 h flex justify-between px-20 items-center h-10">
            <div className="text-white w-60">
              Currency: ({getObjectValue({ value: header?.currency?.name })})
            </div>
            <div className="text-white w-60">
              Exhange Rate: {header?.exchange_rate?.value}
            </div>
          </div>
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
            <div class="inline-block min-w-full overflow-hidden px-5">
              <table class="min-w-full leading-normal">
                <thead>
                  <tr>
                    {tableHeader?.map((head, idx) => (
                      <th
                        key={idx}
                        class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {head.replace(/\_/g, " ").replace("line", "")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.line?.map((lineItem) => (
                    <tr>
                      {tableHeader?.map((prop, idx) => {
                        console.log(
                          "the prop and prop map",
                          lineItem[prop],
                          prop
                        );
                        return (
                          <td class="px-5 py-5 border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-no-wrap">
                              {getObjectValue({
                                value: lineItem[prop],
                                prop: prop,
                              })}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  {/* <tr align="right">
                                <td colspan={tableHeader.length - 3}></td>
                                <td colspan={3}>
                                    <div className='border-t-2 bg-gray-800 mt-2'></div>
                                    <div className="grid px-2 py-6">
                                        <div className='flex justify-between'>
                                            <span>Sub total</span>
                                            <span>{formatter(getSubtotal())}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span>Total Inclusive Discount</span>
                                            <span>{formatter(getTotalInclusiveDiscount())}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span>Tax</span>
                                            <span>{formatter(getTax())}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span>Total Inclusive Tax</span>
                                            <span>{formatter(getTotalInclusiveTax())}</span>
                                        </div>
                                    </div>
                                    <div className='border-b-2 bg-gray-300 mb-2'></div>
                                </td>
                            </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-10 px-20 mb-4">
            <p className="capitalize font-semibold">Remarks</p>
            <p className="text-gray-400">{header?.remark}</p>
          </div>
          {uploads?.general_attachment && (
            <div className="px-20 bg-gray-100 py-8">
              <p className="font-semibold">Uploads</p>
              <div className="flex gap-4">
                <div className="rounded-lg overflow-hidden border w-16 h-16 p-1">
                  <img
                    className="w-full h-full"
                    src={uploads?.general_attachment}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="bg-gray-400 h flex justify-between px-20 items-center min-h-10 leading-none py-4">
            <div className="text-white">
              Thanks for being a swift ng limited customer
            </div>
            <div className="text-white w-60">Need help? help@swift.com.ng</div>
          </div>
          <div className="px-20 py-5">
            <div className="flex gap-10">
              {/* <button onClick={handleSubmit} class="flex-grow bg-primary text-white font-bold py-2 px-4 rounded inline-flex justify-center items-center">
                    <svg class="w-4 h-4 mr-2" width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5795 1.00878C12.8342 0.766235 13.1733 0.632143 13.525 0.634805C13.8768 0.637466 14.2137 0.776673 14.4648 1.02305C14.7159 1.26943 14.8614 1.60371 14.8707 1.95535C14.88 2.30699 14.7523 2.64849 14.5146 2.90779L7.29842 11.9326C7.17433 12.0662 7.02457 12.1735 6.85808 12.248C6.6916 12.3224 6.51181 12.3625 6.32947 12.3659C6.14713 12.3693 5.96598 12.3358 5.79685 12.2676C5.62773 12.1994 5.4741 12.0977 5.34515 11.9688L0.559652 7.18327C0.426384 7.05909 0.319493 6.90934 0.245356 6.74295C0.171219 6.57656 0.131354 6.39695 0.128141 6.21482C0.124927 6.03269 0.158431 5.85178 0.226652 5.68288C0.294874 5.51398 0.396416 5.36055 0.52522 5.23175C0.654025 5.10294 0.807454 5.0014 0.976353 4.93318C1.14525 4.86496 1.32616 4.83145 1.50829 4.83467C1.69042 4.83788 1.87004 4.87774 2.03643 4.95188C2.20281 5.02602 2.35256 5.13291 2.47675 5.26618L6.26391 9.05153L12.5451 1.04857C12.5564 1.03465 12.5685 1.02137 12.5813 1.00878H12.5795Z" fill="white"/>
                    </svg>
                    <span>Submit</span>
                </button>

                <button onClick={handlePrint} class="flex-grow bg-primary text-white font-bold py-2 px-4 rounded justify-center inline-flex items-center">
                    <svg class="w-4 h-4 mr-2"  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 15.75H17.125C17.6218 15.7485 18.0979 15.5505 18.4492 15.1992C18.8005 14.8479 18.9985 14.3718 19 13.875V6.375C18.9985 5.87818 18.8005 5.40212 18.4492 5.05081C18.0979 4.6995 17.6218 4.50148 17.125 4.5H2.875C2.37818 4.50148 1.90212 4.6995 1.55081 5.05081C1.1995 5.40212 1.00148 5.87818 1 6.375V13.875C1.00148 14.3718 1.1995 14.8479 1.55081 15.1992C1.90212 15.5505 2.37818 15.7485 2.875 15.75H4" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M14.86 9.75H5.14C4.5104 9.75 4 10.2604 4 10.89V18.36C4 18.9896 4.5104 19.5 5.14 19.5H14.86C15.4896 19.5 16 18.9896 16 18.36V10.89C16 10.2604 15.4896 9.75 14.86 9.75Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M16 4.5V3.375C15.9985 2.87818 15.8005 2.40212 15.4492 2.05081C15.0979 1.6995 14.6218 1.50148 14.125 1.5H5.875C5.37818 1.50148 4.90212 1.6995 4.55081 2.05081C4.1995 2.40212 4.00148 2.87818 4 3.375V4.5" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M16.375 8.25C16.9963 8.25 17.5 7.74632 17.5 7.125C17.5 6.50368 16.9963 6 16.375 6C15.7537 6 15.25 6.50368 15.25 7.125C15.25 7.74632 15.7537 8.25 16.375 8.25Z" fill="white"/>
                    </svg>
                    <span>Print</span>
                </button> */}
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            type="button"
            className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </PreviewContainer>
  );
}

export default Preview;
