
import React from "react"
import { isValidUrl } from "../util"


const DownloadFileWrapper = ({name, children}) => {
  return (<div class="inline-flex gap-1">
    {children}
    {/* <a href={name} target="_blank" class="pointer underline text-primary">Download</a> */}
  </div>)
}

export default ({name}) => {
    // console.log("name",name)
    let extension = ((/\.[0-9a-z]+$/i).exec(name) ?? [])[0] ?? ""
    console.log("extension",extension)

    // switch (extension){
    //   case ".jpeg":
    //   case ".jpg":
    //   case ".png":
    //   case ".gif":
    //     return (<span class="k-file-group-wrapper"><img class="k-file-group k-icon k-i-file-image" src={name}/><span class="k-file-state"></span></span>)
    //   case ".pdf":
    //     return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-pdf"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
    //   case ".xls":
    //   case ".xlsx":
    //     return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-data"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
    //   case ".csv":
    //   case ".txt":
    //     return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-txt"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
    //   case ".docx":
    //   case ".doc":
    //     return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
    //   default:
    //     return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
    // }

    if([".jpeg",".jpg",".png",".gif",".webp"].includes(extension)){
      return isValidUrl(name) ? <span class="k-file-group-wrapper"><img class="k-file-group k-icon k-i-file-image" src={name}/><span class="k-file-state"></span></span> : <></>
    }
    

    switch (extension){
      case ".pdf":
        return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-pdf"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
      case ".xls":
      case ".xlsx":
        return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-data"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
      case ".csv":
      case ".txt":
        return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-txt"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
      case ".docx":
      case ".doc":
        return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
      default:
        return (<DownloadFileWrapper name={name}><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file"></span><span class="k-file-state"></span></span></DownloadFileWrapper>)
    }
  }