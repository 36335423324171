import { createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root{
    --primary: ${({ theme }) => theme.colors.primary};
    --secondary: ${({ theme }) => theme.colors.secondary};
    --accent: ${({ theme }) => theme.colors.accent};
    --accent1: ${({ theme }) => theme.colors.accent};
  }

  .text-primary{
    color:var(--primary)
  }

  .group:hover .group-hover\\:text-primary {
    color:var(--primary)
  }

  .bg-primary{
    background:var(--primary)
  }

  .border-primary{
    border-color:var(--primary)!important
  }

  .text-secondary{
    color:var(--secondary)
  }

  .group:hover .group-hover\\:text-secondary {
    color:var(--secondary)
  }

  .bg-secondary{
    background:var(--secondary)
  }

  .text-accent{
    color:var(--accent)
  }

  .group:hover .group-hover\\:text-accent {
    color:var(--accent)
  }

  .bg-accent{
    background:var(--accent)
  }
`;