import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import AppContext from "../contexts/AppContext";
import { appendQueryToUri } from "../util/generalUtil";

function ListPagination({
  search,
  records,
  itemsPerPage,
  pageRangeDisplayed,
  targetId,
  setLoading,
  changedRecords,
  pages,
  layoutId,
}) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(records);
  const [toogleButton, setToogleButton] = useState(false);

  useEffect(() => {
    setCurrentItems(records);
    changedRecords(records)
  }, [search])


  const fetchData = async (currentPage) => {
    setLoading(true);
    const postData = {
      LayoutId: layoutId,
      targetId: targetId,
      target: "PAGING",
      page: currentPage,
      pageSize: itemsPerPage,
    };
    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView,  postData))
      .then((res) => {
        let resJson = res.options[0].grid
        console.log("the pagination response", resJson);
        setCurrentItems(resJson);
        changedRecords(resJson);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  console.log(currentItems, "currentitems");
  console.log(records, search, "the records");
  console.log('pagess',pages);

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % records.length;
    let requestedPage = event.selected + 1;
    let dataForPage = await fetchData(requestedPage);
  };

  return (
    <>
    {(currentItems?.length > 0 && pages >1)  && 
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pages}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        containerClassName="list-none flex justify-end items-center p-2 space-x-3 text-sm"
        pageLinkClassName="text-black text-[12px] cursor-pointer py-3 px-4 rounded-lg font-semibold border hover:bg-primary outline-none"
        previousLinkClassName="text-black text-[12px] cursor-pointer py-3 px-4 rounded-lg font-semibold border hover:bg-primary outline-none"
        nextLinkClassName="text-black text-[12px] cursor-pointer py-3 px-4 rounded-lg font-semibold border hover:bg-primary outline-none"
        activeLinkClassName={`${
          toogleButton ? "hidden" : "bg-primary text-sm text-gray-100"
        }`}
        disabledClassName={` ${
          toogleButton
            ? "hidden"
            : "hover:bg-gray-300 bg-gray-300 hover:text-black"
        }`}
        disabledLinkClassName={` ${
          toogleButton
            ? "hidden"
            : "hover:bg-gray-300 bg-gray-300 hover:text-black cursor-default"
        }`}
      />}
    </>
  );
}

export default ListPagination;
