import React from 'react';
import TransformValue from './TransformValue';
import classNames from 'classnames';

const excludeKeys = [
    "drillable",
    "drillid",
    "target_name",
    "target_type",
    "targetid"
]

function VerticalSummary({options}) {
    options = (options ?? [])[0];
    const itemKeys = Object.keys((options?.grid ?? [])[0] ?? {}).filter(key => !excludeKeys.includes(key))
    return (
      <>
        {options?.grid.map(record => (
        <li className='modal-list-item list-none'>
            <ul className='grid gap-1 my-6 border-b border-gray-200 mb-3'>
                {itemKeys?.map((str, idx) => {
                    return (
                        <li className={classNames('flex justify-between')} key={idx}> 
                            <div className={classNames("py-2 px-3 w-36",{"bg-gray-200": ((idx+1) % 2) != 0})}><span className='text-gray-700 font-semibold text-xs'>{str}</span></div>
                            <div className={classNames("ml-0.5 py-2 px-3 flex-grow", {"bg-gray-200": ((idx+1) % 2) != 0})}>
                                <span className='text-xs'>
                                    <TransformValue value={(record[str])} />
                                </span>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </li>
    ))}
    </>
    );
}

export default VerticalSummary;