import React from 'react';

function MainModuleArea1({children}) {
    return (
        <div className='pt-14 lg:pt-0 w-rb'>
            <div className="grid h-full">
                {children}
            </div>
        </div>
      
    );
}

export default MainModuleArea1;