import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import apiEndpoints from '../apiEndpoints';
import appFetch from '../appFetch';
import ExcelTemplate from './ExcelTemplate';
import uuid from 'react-uuid';
import FileThumbnail from './FileThumbnail';
import AppContext from '../contexts/AppContext';
import { read, utils } from 'xlsx';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary',
    cancelButton: 'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary'
  },
  buttonsStyling: false
}))

const allowedTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
]

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

const ImportDialog = ({targetId,isOpen,templateName,templateHeader, closeModal,updateRecord}) => {

    const [files, setFiles] = useState(null);
    const [uploadComplete, setUploadComplete] = useState(false)
    const [uploading,setUploading] = useState(false)
    const {context} = useContext(AppContext)

    // useEffect(() => {
    //     if(fieldValue?.length == 0 && files?.length){
    //       setFiles(null);
    //       setUploadComplete(false);
    //     }
    // }, [fieldValue]) 

    const fieldValue = null;

    const getName = (str) => {
        return str?.split('/')?.pop()
      }
  
    const thumbs = files?.map(file => (
        <div style={thumb} key={file}>
        <div style={thumbInner}>
            <img
            src={file}
            style={img}
            />
        </div>
        </div>
    ));
  
      const {
        getRootProps,
        getInputProps,
        rootRef, // Ref to the `<div>`
        inputRef, // Ref to the `<input>`
        open,
        isFocused,
        isDragAccept,
        isDragReject
      } = 
      useDropzone({
          accept:allowedTypes.join(","),
          maxFiles:1,
          onDrop: acceptedFiles => {
            console.log("accepted files", acceptedFiles);
  
              if(uploadComplete){
                setUploadComplete(false);
              }
              
              let myFiles = acceptedFiles.map(file => {
                return Object.assign(file, {
                  preview: URL.createObjectURL(file),
                  uploaded:false,
                  key:uuid()
              })
            })
              setFiles(prev => [...(prev ?? []), ...myFiles]);
          }
        });
  
      const removeFile = (removeKey) => {
        let newFileList = files?.filter(({key}) => key !== removeKey);
        setFiles(newFileList);
      }

      const removeResource = (url) => {
        
      }

      const  VerifyHeaders = async (file, templateHeader) => {
        const f = await (file).arrayBuffer();
        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]]; 
        const header = Object.keys(utils.sheet_to_json(ws)[0])
        const tHeader = Object.keys(templateHeader)
        console.log({header,tHeader})
        return tHeader.every((cell, idx) => cell === header[idx]) && tHeader.length === header.length
      }

        const uploadFiles = async () => {
    
            let formData = new FormData();
            //   files.filter(({uploaded}) => !uploaded).forEach(file => {
            //       formData.append("excelSheet", file)
            //   });

            let file = files[0];

            // console.log("file is allowed", await VerifyHeaders(file, templateHeader));
            //  return;

            formData.append("excelSheet", file);
            formData.append("pageButtonActionId", targetId.toString());
            formData.append("menuId",context?.menuId.toString())
            formData.append("buttonAction", "UPLOAD")

            console.log("formData",formData)
    
            setUploading(true)
            let token = localStorage.getItem("token") ?? ""
            appFetch.post(apiEndpoints.uploadData, {
                headers:{ 
                    "Authorization": `Bearer ${token}`
                },
                "body":formData
            })
            .then(res => {
                setFiles(null);
                let {ERROR_MESSAGE:errMsg,ERROR_NUMBER:errNo} = res;
        
                MySwal.fire({
                  html:errMsg,
                  icon: errNo === 0 ? "success" : "error",
                })

                if (errMsg == "SUCCESS") {
                  let savedRecords = res?.layout?.children?.find(
                    ({ type }) => type === "SavedRecordList1"
                  );
                  updateRecord(savedRecords);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                setUploading(false)
                setUploadComplete(true)
            })
        }
  
      console.log("files", files);
  
      const getSize = (size) => {
        let sizeMb = (size/(1024*1024)).toFixed(1)
  
        console.log("sizeMb",sizeMb)
  
        if(parseFloat(sizeMb) <= 0){
          return (size/1024).toFixed(1)+" KB"
        }
  
        return sizeMb+" MB" 
      }

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                 <div className='flex justify-end mb-3'>
                  <ExcelTemplate
                    templateName={templateName}
                    templateHeader={templateHeader}
                  />
                  </div>

                  <div>
                    <div class="k-widget k-upload">
                      <div class="k-dropzone">
                        <div></div>
                        {files?.length > 0 && !uploadComplete && !uploading && (
                          <button
                            onClick={uploadFiles}
                            class="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md buttons-container-button"
                          >
                            <span
                              role="presentation"
                              class="k-button-icon k-icon k-i-upload"
                            ></span>
                            <span class="k-button-text">Upload</span>
                          </button>
                        )}
                        {uploadComplete && (
                          <div class="k-upload-status k-upload-status-total">
                            <span class="k-i-checkmark k-icon"></span>Done
                          </div>
                        )}
                        {uploading && (
                          <div class="k-upload-status k-upload-status-total">
                            <span class="k-i-loading k-icon"></span>Uploading...
                          </div>
                        )}
                      </div>

                      {(files?.length ?? 0) == 0 && (
                        <div
                          class="k-external-dropzone"
                          {...getRootProps({
                            isFocused,
                            isDragAccept,
                            isDragReject,
                          })}
                          tabindex="0"
                        >
                          <div
                            class="k-dropzone-inner"
                            style={{
                              background: "#FAFAFA",
                              border: "1.2px dashed rgba(169, 67, 143, 0.2)",
                              boxSizing: "border-box",
                            }}
                          >
                            <span class="k-dropzone-hint">
                              <span className='font-medium'>Upload File</span>
                            </span>
                            <span class="k-dropzone-note">
                              <span></span>
                            </span>
                          </div>
                        </div>
                      )}

                      <ul class="k-upload-files k-reset">
                        {files?.map(({ key, name, uploaded, size }) => (
                          <li class="k-file k-file-success" key={key}>
                            <div class="k-file-single">
                              <FileThumbnail name={name} />
                              <span class="k-file-name-size-wrapper">
                                <span class="k-file-name" title="Group 4.png">
                                  {name}
                                </span>
                                {!uploaded && (
                                  <span class="k-file-size">
                                    {getSize(size)}
                                  </span>
                                )}
                                {uploaded && (
                                  <span class="k-file-validation-message k-text-success">
                                    File(s) successfully uploaded.
                                  </span>
                                )}
                              </span>
                              {!uploaded && (
                                <strong class="k-upload-status">
                                  <button
                                    type="button"
                                    onClick={() => removeFile(key)}
                                    tabIndex="-1"
                                    class="k-button k-button-icon k-flat k-upload-action"
                                  >
                                    <span
                                      aria-label="Remove"
                                      title="Remove"
                                      class="k-icon k-delete k-i-x"
                                    ></span>
                                  </button>
                                </strong>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>

                      <ul class="k-upload-files k-reset">
                        {fieldValue?.map((str, idx) => (
                          <>
                            <div class="k-file-single">
                              <FileThumbnail name={getName(str)} />
                              <span class="k-file-name-size-wrapper">
                                <span class="k-file-name" title="Group 4.png">
                                  {getName(str)}
                                </span>
                              </span>
                              <strong class="k-upload-status">
                                <button
                                  type="button"
                                  onClick={() => removeResource(str)}
                                  tabIndex="-1"
                                  class="k-button k-button-icon k-flat k-upload-action"
                                >
                                  <span
                                    aria-label="Remove"
                                    title="Remove"
                                    class="k-icon k-delete k-i-x"
                                  ></span>
                                </button>
                              </strong>
                            </div>
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
}

export default ImportDialog;