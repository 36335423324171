import { useTheme } from "styled-components"

export function EditInactiveIcon(props) {
  const theme = useTheme()
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 13V16H7L16 7L13 4L4 13Z"
          fill="#f9f9f9"
          stroke={theme?.colors?.primary}
          strokeWidth="2"
        />
      </svg>
    )
  }
  
export function EditActiveIcon(props) {
  const theme = useTheme()
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 13V16H7L16 7L13 4L4 13Z"
          fill={theme?.colors?.primary}
          stroke="#FDF9EB"
          strokeWidth="2"
        />
      </svg>
    )
  }
  
export function ViewInactiveIcon(props) {
  const theme = useTheme()
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 4H16V10" stroke={theme?.colors?.primary} strokeWidth="2" />
        <path d="M16 4L8 12" stroke={theme?.colors?.primary} strokeWidth="2" />
        <path d="M8 6H4V16H14V12" stroke={theme?.colors?.primary} strokeWidth="2" />
      </svg>
    )
  }
  
export function ViewActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 4H16V10" stroke="#FDF9EB" strokeWidth="2" />
        <path d="M16 4L8 12" stroke="#FDF9EB" strokeWidth="2" />
        <path d="M8 6H4V16H14V12" stroke="#FDF9EB" strokeWidth="2" />
      </svg>
    )
  }
  
export function DeleteInactiveIcon(props) {
    const theme = useTheme()
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="#f9f9f9"
          stroke={theme?.colors?.primary}
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke={theme?.colors?.primary} strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke={theme?.colors?.primary} strokeWidth="2" />
      </svg>
    )
  }
  
export function DeleteActiveIcon(props) {
  const theme = useTheme()
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill={theme?.colors?.primary}
          stroke="#FDF9EB"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#FDF9EB" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#FDF9EB" strokeWidth="2" />
      </svg>
    )
  }

  export function PrintActiveIcon(props) {
    const theme = useTheme()
    return (
      <svg 
        {...props}
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" viewBox="0 0 24 24" 
        width="10"
        height="10"
        stroke="#FDF9EB" 
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
      </svg>
    )
  }

  export function PrintInactiveIcon(props) {
    const theme = useTheme()
    return (
      <svg 
        {...props}
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" viewBox="0 0 24 24" 
        width="10"
        height="10"
        stroke={theme?.colors?.primary}
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
      </svg>
    )
  }


  export function ApprovedActiveIcon(props) {
    const theme = useTheme()
    return (
     <svg 
     {...props}
     xmlns="http://www.w3.org/2000/svg" 
     fill="none" viewBox="0 0 24 24" 
     width="10"
    height="10"
     stroke-width="2" 
     stroke="currentColor" 
     >
    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
    </svg>
    )
  }

  export function ApprovedInactiveIcon(props) {
    const theme = useTheme()
    return (
     <svg 
     {...props}
     xmlns="http://www.w3.org/2000/svg" 
     fill="none" viewBox="0 0 24 24" 
     width="10"
    height="10"
     stroke-width="2" 
     stroke={theme?.colors?.primary} 
     >
    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
    </svg>
    )
  }


  export function DependencyActiveIcon(props) {
    const theme = useTheme()
    return (
      <svg 
      {...props}
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" viewBox="0 0 24 24" 
      strokeWidth={1.5} 
      width = '10'
      height = '10'
      stroke="currentColor" 
      >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
      </svg>
    )
  }

  export function DependencyInactiveIcon(props) {
    const theme = useTheme()
    return (
      <svg 
      {...props}
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" viewBox="0 0 24 24" 
      strokeWidth={1.5} 
      width = '10'
      height = '10'
      stroke={theme?.colors?.primary}
      >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
      </svg>
    )
  }