import styled, { useTheme } from "styled-components"

const EmptyRecordIconContainer = styled.div`
    width: 141px;
    height: 141px;
`

const EmptyRecord = ({children}) => {
    const theme = useTheme()
    return (
        <div className='grid place-items-center'>
            <EmptyRecordIconContainer className="bg-accent rounded-full grid place-items-center">
                <svg width="52" height="60" viewBox="0 0 65 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M61.9404 16.7766C60.3293 14.5594 58.0821 11.9625 55.6168 9.47344C53.1514 6.98437 50.5793 4.71562 48.3832 3.08906C44.6411 0.31875 42.8257 0 41.7857 0H5.80357C2.60464 0 0 2.62969 0 5.85938V69.1406C0 72.3703 2.60464 75 5.80357 75H59.1964C62.3954 75 65 72.3703 65 69.1406V23.4375C65 22.3875 64.6843 20.5547 61.9404 16.7766ZM52.3296 12.7922C54.5582 15.0422 56.3039 17.0672 57.5946 18.75H46.4239V7.47656C48.0907 8.77969 50.1011 10.5422 52.325 12.7922H52.3296ZM60.3571 69.1406C60.3571 69.7781 59.8279 70.3125 59.1964 70.3125H5.80357C5.49685 70.3088 5.20372 70.1842 4.98682 69.9652C4.76992 69.7462 4.64647 69.4503 4.64286 69.1406V5.85938C4.64286 5.22656 5.17679 4.6875 5.80357 4.6875H41.7857V21.0938C41.7857 21.7154 42.0303 22.3115 42.4656 22.751C42.901 23.1906 43.4915 23.4375 44.1071 23.4375H60.3571V69.1406Z" fill={theme?.colors?.primary}/>
                </svg>
            </EmptyRecordIconContainer>
            <div className='h-4'></div>
            {children}
        </div>
    )
}
export default EmptyRecord;