import * as React from "react";

const OnlineIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="24px"
    viewBox="0 0 52 52"
    enable-background="new 0 0 52 52"
    fill={props?.fill}
  >
    <g display="none">
      <path
        display="inline"
        opacity="0.2"
        enable-background="new"
        d="M0,0v52h52V0H0z M49.9,49.9H2.1V2.1h47.8
      C49.9,2.1,49.9,49.9,49.9,49.9z"
      />
    </g>
    <g display="none">
      <rect
        x="2"
        y="2"
        display="inline"
        fill="inherit"
        stroke-width="0.25"
        stroke-miterlimit="10"
        width="48"
        height="48"
      />
    </g>
    <g display="none">
      <g display="inline" opacity="0.5">
        <line
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          x1="50"
          y1="2"
          x2="2"
          y2="50"
        />
        <line
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          x1="2"
          y1="2"
          x2="49.9"
          y2="50"
        />
        <rect
          x="2"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="4"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="6"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="8"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="10"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="12"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="14"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="16"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="18"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="20"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="22"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="24"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="26"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="28"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="30"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="32"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="34"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="36"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="38"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="40"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="42"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="44"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="46"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="48"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="2"
          height="48"
        />
        <rect
          x="2"
          y="48"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="46"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="44"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="42"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="40"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="38"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="36"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="34"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="32"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="30"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="28"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="26"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="24"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="22"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="20"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="18"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="16"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="14"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="12"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="10"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="8"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="6"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="4"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
        <rect
          x="2"
          y="2"
          fill="inherit"
          stroke-width="0.25"
          stroke-miterlimit="10"
          width="48"
          height="2"
        />
      </g>
    </g>
    <path
      d="M50,20.5c0-1.2-1-2.2-2.2-2.2H33.7c-1.2,0-2.2,1-2.2,2.2v23.7c0,1.2,1,2.2,2.2,2.2h14.1c1.2,0,2.2-1,2.2-2.2
    V20.5z"
    />
    <g>
      <path
        d="M27.7,29.4H8.4c-0.7,0-1.3-0.7-1.3-1.3V11.9c0-0.7,0.7-1.3,1.3-1.3h28.1c0.7,0,1.3,0.7,1.3,1.3v2.5h5.1V9
      c0-1.8-1.6-3.4-3.4-3.4H5.4C3.6,5.6,2,7.2,2,9v22.2c0,1.8,1.6,3.4,3.4,3.4h22.3V29.4z"
      />
      <path
        d="M27.7,44.2v-3h-1.9c-0.5,0-0.9-0.4-0.9-0.9v-1.7c0-0.5-0.4-0.9-0.9-0.9h-3.4c-0.5,0-0.9,0.4-0.9,0.9v1.7
      c0,0.5-0.4,0.9-0.9,0.9h-2.6c-1.8,0-3.4,1.6-3.4,3.4V45c0,0.7,0.7,1.3,1.3,1.3h14.1C27.8,45.7,27.7,45,27.7,44.2z"
      />
    </g>
    <circle cx="40.8" cy="43.8" r="1.6" />
    <path
      d="M46.7,40.2c0,0.4-0.4,0.9-0.9,0.9H35.6c-0.4,0-0.9-0.4-0.9-0.9V23.5c0-0.4,0.4-0.9,0.9-0.9h10.2
    c0.4,0,0.9,0.4,0.9,0.9V40.2z"
    />
  </svg>
);

export default OnlineIcon;
