import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import LogoutIcon from '../icons/LogoutIcon';
import styled from "styled-components"
import WithLoader from './WithLoader';
import renderComponent from '../renderComponent';
import AppContext from '../contexts/AppContext';
import appFetch from '../appFetch';
import apiEndpoints from '../apiEndpoints';
import SidebarModal from './SidebarModal';
import formData from "../samples/role.json"
import { getFromLS } from '../util/storage';
import { useHistory } from 'react-router-dom';
import { appendQueryToUri } from '../util/generalUtil';

const Role = styled.div`
    background: ${({theme}) => theme?.colors.primary};
`

const SettingsModal = ({options, loadForm, setLoading}) => {
    const history = useHistory()

    const logout = () => {
        setLoading(true)
        appFetch.get(apiEndpoints.logout)
        .then(res => {
            //const loginUrl = getFromLS("loginUrl");
            if(res?.ERROR_MESSAGE === "SUCCESS"){
                localStorage.clear(); 
                let pathName = window.location.pathname.slice(1).split("/")[0];
                // window.location = `/${pathName}/login`
                history.push(`/${pathName}/login`);
            }
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }

    return (
        <>
            <div className='pt-3 px-3 rounded-lg overflow-hidden'>
                <div class="grid w-44">
                    {options?.map((item) => (
                        <div class="border-b cursor-pointer border-color-gray-300 py-2" onClick={() => loadForm(item)}>
                            <span className='text-sm'>{item.name}</span>
                        </div>
                    ))}
                    <div class="border-b cursor-pointer bg-primary text-white border-color-gray-300 py-2 -mx-3 p-3" onClick={logout}>
                            <span className='text-sm'>Logout</span>
                        </div>
                </div>
            </div>
        </>
    );
}

export const SettingsForm = ({item, updateFormState}) => {
    const theme = useContext(ThemeContext);
    const [inProp, setInProp] = useState(false)
    const [form,setForm] = useState(null)
    const {context} = useContext(AppContext)
    const [loading, setLoading] = useState(false)

    const {name} = item

    console.log("SettingsForm name => ",form);

    useEffect(() => {
        setLoading(true);
        setInProp(true)

        let postData = {
            "urlAppCode":null,
            "urlMenuName":null,
            "layoutId": item?.layoutid,
            "targetId": item?.targetid,
            "target": item?.target?.toUpperCase(),
            "applicationId": context?.applicationId,
            "schemaId": item?.schemaid,
            "txnGroup": null,
            "parentDropValue":null
        }

        // setForm(formData)
        // setLoading(false)

        appFetch.get(appendQueryToUri(apiEndpoints.menuView,postData)).then(result => {
            let form = result;
            console.log("value of result", result)
            setForm(form)
        })
        .catch(e => console.log(e.message))
        .finally(() => setLoading(false))
    },[])

    return (
        <SidebarModal inProp={inProp} closeSidebar={() => {
            updateFormState()
            setInProp(false)
        }} loading={loading}>
            {form && renderComponent(form, {layoutid: item.layoutid})}
        </SidebarModal>
    );
}

export default WithLoader(SettingsModal);