import React, { useState } from "react";
import formatter from "../util/formatter";
import uuid from "react-uuid";
import {
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
} from "./Accordion";
import styled from "styled-components";
import { ScreenLoader, ScreenOverlay } from "./ScreenLoader";
import { Transition } from "react-transition-group";
import "../assets/styles/sidebar-modal.css";
import AddFavouriteItem from "./AddFavouriteItem";
import { XIcon } from "@heroicons/react/solid";
import SidebarModal from "./SidebarModal";
import WithLoader from "./WithLoader";
import { ViewInactiveIcon } from "../LineIcons";
import { Dialog } from "@headlessui/react";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import renderComponent from "../renderComponent";
import DrillModalDialog from "./DrillModalDialog";
import { appendQueryToUri } from "../util/generalUtil";
import { FavouriteReport } from "../constants/variables";
import { useContext } from "react";
import LeftBarContext from "../contexts/LeftBarContext";
import SidebarModal2 from "./SidebarModal2";

// let FavouriteData = {
//   children: null,
//   name: null,
//   options: [
//     {
//       code: "ageing_purchase_invoice",
//       name: "Ageing Purchase Invoice",
//       target: "modal",
//       target_id: 30891,
//     },
//     {
//       code: "ageing_sales_invoice",
//       name: "Ageing Sales Invoice",
//       options: [
//         {
//           code: "Balance : &#8358;312,000",
//           drillid: "1 - 30 DAYS",
//           layoutid: "51",
//           name: "1 - 30 DAYS",
//           ref: "2",
//           target: "modal",
//           targetid: 30892,
//           value: "Total : &#8358;312,000",
//         },
//       ],
//       target: "modal",
//       target_id: 30892,
//     },
//     {
//       code: "customer_balance",
//       name: "Customer Balances",
//       options: [
//         {
//           code: "SPAR SUPERMARKET",
//           drillid: "7",
//           layoutid: "51",
//           name: "SPR",
//           ref: "Debt",
//           target: "modal",
//           targetid: 30893,
//           value: "Balance : &#8358;300,000",
//         },
//       ],
//       target: "modal",
//       target_id: 30893,
//     },
//   ],
//   selectedsubscriberid: null,
//   selectedtargetid: null,
//   type: "FavouriteList1",
// };

const FavouriteItemCard = styled.div`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.primary};
`;

const AddFavouriteItemButton = styled.button`
  background: ${({ theme }) => theme?.colors?.primary};
`;

function FavouriteList2(props) {
  let { options, name, children, setLoading, setSidebarState } = props;
  const [inProp, setInProp] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { menuCollapse, setMenuCollapse, setMobileMenuOpen } =
    useContext(LeftBarContext);
  console.log("new options", options);

  const openFavouriteSidebar = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setInProp(true);
      // setInProp(true);
    }, 1000);
  };

  const showDetail = (item) => {
    console.log("item", item);

    setLoading(true);
    let postData = {
      layoutId: item.layoutid,
      targetId: item.targetid,
      target: item.target,
      drillId: item.drillid?.toString(),
      targetName: item?.targetname,
      targetType: item?.targettype,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("modal res", res);
        const { layoutid, targetid, drillid } = item;
        setModalData({
          ...res,
          layoutid,
          targetid,
          drillid,
          target: FavouriteReport,
        });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <div className="py-10">
      {!menuCollapse && (
        <>
          <header className="flex items-center mb-3 border-y border-gray-200 bg-gray-100 -mx-8 ">
            <h3 className="font-semibold tracking-wide ml-8 py-1">{name}</h3>
          </header>
          {isOpen && (
            <DrillModalDialog
              isOpen={isOpen}
              closeModal={() => setIsOpen(false)}
              item={modalData}
            />
          )}
          <SidebarModal2 inProp={inProp} closeSidebar={() => setInProp(false)}>
            <AddFavouriteItem options={options} />
          </SidebarModal2>
          <div className="my-5">
            {/* <h1 className="font-bold">{name}</h1> */}
            <Accordion>
              {options?.map((item, idx) => {
                let { name, data, options } = item;
                return (
                  <AccordionItem key={idx}>
                    <AccordionHead>
                      {name}
                      {/* {options?.length > 0 &&
                  <button className='absolute right-12 inline-flex items-center p-1 px-2 rounded-md bg-background-yellow shadow hover:text-main-black' 
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    showDetail(item)
                  }}>
                  <ViewInactiveIcon className="w-5 h-5 mr-0.5"
                    aria-hidden="true"/>
                    <span className="text-xs font-medium">View</span> 
                  </button>
                } */}
                    </AccordionHead>
                    <AccordionBody>
                      <ul className="pl-6">
                        {options?.map((item, idx) => (
                          <li key={idx} className="flex content-center mb-6">
                            <FavouriteItemCard className="initial drop-shadow font-semibold text-md pb-2 pt-2 w-14 inline-flex justify-center px-1 rounded mr-4 self-start">
                              {item.ref}
                            </FavouriteItemCard>
                            <div className="flex-1 grid leading-normal">
                              <h1
                                className="font-semibold hover:underline cursor-pointer text-md"
                                onClick={() => showDetail(item)}
                              >
                                {item.name}
                              </h1>
                              <h3
                                className="font-medium text-sm"
                                dangerouslySetInnerHTML={{ __html: item.code }}
                              />
                              <h5
                                className="font-medium text-xs"
                                dangerouslySetInnerHTML={{ __html: item.value }}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </AccordionBody>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>

          {/* {options?.length > 0 && (
            <>
              <AddFavouriteItemButton
                className="uppercase text-white py-5 auto font-semibold rounded w-full"
                onClick={openFavouriteSidebar}
              >
                Filter
              </AddFavouriteItemButton>
              <div className="mt-5">
                <p style={{ color: "#9A9FAD" }} className="text-sm pb-7">
                  Add favourite report you want to be visible on the dashboard
                </p>
              </div>
            </>
          )} */}
        </>
      )}
    </div>
  );
}

export default WithLoader(FavouriteList2);
