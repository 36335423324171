import * as React from "react";

const CupIcon = (props) => (
  <svg
    viewBox="0 0 19 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2226 0H3.92735V1.32652H0V7.54868C0 9.9534 2.65128 11.9322 5.25299 12.1381C6.1071 13.0645 7.60941 13.7374 8.94648 14.0243V15.6506C7.66787 16.0482 5.3839 16.9823 5.25299 18H14.0279C13.8957 16.9823 11.3792 16.0469 10.098 15.6506V14.0243C11.4402 13.7374 13.0098 13.0645 13.8652 12.1381C16.4656 11.9322 19 9.9534 19 7.54868V1.32652H15.2226V0ZM1.20617 2.50508H3.83584V7.86519C3.83584 8.9228 4.10529 9.92895 4.57937 10.8347C2.61188 10.2004 1.20617 8.5664 1.20617 6.65447V2.50508ZM11.5063 9.04246L9.54385 7.94753L7.5789 9.04246L7.95384 6.72652L6.36511 5.08735L8.5601 4.74896L9.54385 2.64403L10.5251 4.74896L12.72 5.08735L11.1313 6.72652L11.5063 9.04246ZM14.4232 11.0059C14.8998 10.0589 15.1731 9.01158 15.1731 7.90379V2.53981H17.8091V6.63903C17.8091 8.63717 16.397 10.3432 14.4232 11.0059Z"
      fill="inherit"
    />
  </svg>
);

export default CupIcon;
