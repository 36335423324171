import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../endpoints";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FilterIcon from "../icons/FilterIcon";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import DrillModalDialog from "./DrillModalDialog";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import WithLoader from "./WithLoader";
// import '../index.css';

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-96 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No chart available</h2>
    </div>
  );
};

function ChartControl1({ setLoading, name, options, selectedChartControl }) {
  const { context } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const gridRef = useRef(null);
  // const [loading, setLoading] = useState(false);
  options = selectedChartControl?.options ?? options;

  const graphData = (options ?? [])[0] ?? {};

  for (var i = 0; i < graphData?.series?.length; i++) {
    graphData.series[i] = { ...graphData.series[i] };
  }

  console.log("the graphData is", graphData);
  console.log("the modalData is", modalData);

  const showDetail = (category) => {
    console.log("item", category);

    setLoading(true);
    let postData = {
      layoutId: graphData.layoutid,
      targetId: graphData.targetid,
      target: graphData.target,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        const { layoutid, targetid, drillid } = graphData;
        setModalData({ ...res, layoutid, targetid, drillid });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const graphOptions2 = {
    chart: {
      type: "spline",
    },
    title: {
      text: "",
    },
    subTitle: {
      text: "",
    },
    legend: { enabled: false },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        shadow: true,
      },
    },
  };
  const graphOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    subTitle: {
      text: "",
    },
    legend: { enabled: false },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        borderRadius: 4,
        pointWidth: 20,
        groupPadding: 0.38,
        shadow: false,
      },
    },
    ...graphData,
  };

  const graphOptions3 = {
    chart: {
      type: "column",
    },
    title: {
      text: "Monthly Average Rainfall",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {},
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    // plotOptions: {
    // column: {
    //     borderRadius:4,
    //     pointWidth: 20,
    //     groupPadding: 0.38,
    //     shadow:false,
    // }
    // },
    plotOptions: {
      column: {
        borderRadius: 4,
        pointWidth: 20,
        groupPadding: 0.38,
        shadow: false,
      },
      series: {
        point: {
          events: {
            click: function () {
              // Call the custom function with the category as an argument
              showDetail(this.category);
            },
          },
        },
      },
    },
    ...graphData,
  };

  console.log("graphOptions", graphOptions, options);

  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
        />
      )}

      <div>
        <div id="graph" className="mb-10">
          {Object.keys(graphData?.xAxis ?? {}).length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{ ...graphOptions3 }}
              containerProps={{ style: { width: "100%" } }}
            />
          ) : (
            <EmptyRecord />
          )}
        </div>
      </div>
    </>
  );
}

export default WithLoader(ChartControl1);
