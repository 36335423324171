import React, { Component, Fragment } from 'react';
import api from "../endpoints"
import renderComponent from "../renderComponent";
import classNames from 'classnames';
import uuid from 'react-uuid';
import ReactJson from 'react-json-view';


class HomeLayout3 extends Component {

    constructor(props) {
        super(props);
      }

    componentDidMount(){

    }

    layoutRule = [
        "row-span-full col-span-full", 
        "col-start-1",
        "col-start-2",
        "col-start-3",
        // "col-start-4 max-w-sm right-sidebar"
    ]



    render() {
       return (
            // <div className="home-layout-1 flex w-full bg-white h-full absolute">
            <div className="home-layout-3 space-x-4 grid grid-cols-3 w-full bg-white h-full overflow-y-auto">
                {this.props.subcomponents.map((config,idx) => (
                    <section key={idx} className={classNames(this.layoutRule[idx])}>
                        {renderComponent(config)}
                    </section>
                    // <Fragment key={idx}>
                    //     {renderComponent(config)}
                    // </Fragment>
                    ))
                }
            </div>
       )
    }
}

export default HomeLayout3;