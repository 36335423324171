import React, { useContext } from "react";
import Moment from "react-moment";
import DownloadIcon from "../icons/DownloadIcon";
import formatter from "../util/formatter";
import uuid from "react-uuid";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import EmptyTransactionMiniIcon from "../icons/EmptyTransactionMiniIcon";
import styled from "styled-components";
import icons from "../icons";
import { useTheme } from "styled-components";
import LeftBarContext from "../contexts/LeftBarContext";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';


const LeftBarToggler = styled.div`
  width: 32px;
  height: 32px;
  /* background: #fcfcfc; */
`;

function RecentTransactionList2(props) {
  const theme = useTheme();
  const { context } = useContext(AppContext);
  let { children, name, options } = props;
  const { menuCollapse, setMenuCollapse, setMobileMenuOpen } =
    useContext(LeftBarContext);

  return (
    <div>
      {/* {!menuCollapse && <hr className="-mx-8 -mt-2 mb-2" />} */}

      {menuCollapse && (
        <LeftBarToggler
          className="hidden cursor-pointer md:inline-grid place-items-center"
          onClick={() => setMenuCollapse(!menuCollapse)}
        >
          {React.createElement(icons["FileIcon"], {
            stroke: "gray",
            width: "22px",
            height: "22px",
          })}
        </LeftBarToggler>
      )}

      {!menuCollapse && (
        <>
          <div className="py-10">
            <header className="flex items-center mb-3 border-y border-gray-200 bg-gray-100 -mx-8 ">
              <h3 className="font-semibold tracking-wide ml-8 py-1">
                {name}
              </h3>
            </header>
            <ul className="grid lg:grid-cols gap-y-2">
              {options &&
                options.map(
                  (
                    { recent_date: date, recent_text: desc, recent_icon: icon },
                    idx
                  ) => (
                    <>
                      <li
                        //   className="grid px-3 border border-light-gray rounded-ten h-75 items-center py-2"
                        key={idx}
                      >
                        <div className="grid grid:auto-1fr-auto-auto items-center gap-5">
                          <div className="grid items-center justify-items-center rounded-full text-center px-2 py-2 leading-tight bg-accent h-22 w-22">
                            {icons[icon] &&
                              React.createElement(icons[icon], {
                                stroke: theme?.colors?.primary,
                              })}
                          </div>
                          <div className="grid items-center">
                            <div
                              className="desc text-xs font-medium text-gray-500 flex 1 leading-tight"
                              dangerouslySetInnerHTML={{ __html: desc }}
                            ></div>
                            <div className="pt-1 text-xs text-[#AAAAAA] font-normal">
                              {date}
                            </div>
                          </div>
                        </div>
                      </li>
                      <hr className="py-1"></hr>
                    </>
                  )
                )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

export default RecentTransactionList2;
