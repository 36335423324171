import React, { Component, Fragment } from 'react';
import api from "../endpoints"
import renderComponent from "../renderComponent";
import classNames from 'classnames';
import uuid from 'react-uuid';
import ReactJson from 'react-json-view';


class HomeLayout1 extends Component {

    constructor(props) {
        super(props);
      }

    componentDidMount(){

    }

    layoutRule = [
        "max-w-xs overflow-auto", 
        "px-12 pt-12 overflow-auto",
        "max-w-sm right-sidebar"
    ]



    render() {
       return (
            // <div className="home-layout-1 flex w-full bg-white h-full absolute">
            <div className="home-layout-1 flex w-full bg-white h-full">
                {this.props.subcomponents.map((config,idx) => (
                    // <section key={idx} className={classNames(this.layoutRule[idx],"flex-1")}>
                    //     {renderComponent(config)}
                    // </section>
                    <Fragment key={idx}>
                        {renderComponent(config)}
                    </Fragment>
                    ))
                }
            </div>
       )
    }
}

export default HomeLayout1;