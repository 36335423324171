import React, { useContext, useEffect, useState } from "react";
import api from "../endpoints";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FilterIcon from "../icons/FilterIcon";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import WithLoader from "./WithLoader";
import DrillModalDialog from "./DrillModalDialog";

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-96 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No chart available</h2>
    </div>
  );
};

function ChartControl3({ setLoading, name, options }) {
  const { context } = useContext(AppContext);
  const [modalData, setModalData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  options = context?.chartControlOptions ?? options;

  const graphData = options[0];

  const showDetail = (category) => {
    console.log("item", category);

    setLoading(true);
    let postData = {
      layoutId: graphData.layoutid,
      targetId: graphData.targetid,
      target: graphData.target,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        const { layoutid, targetid, drillid } = graphData;
        setModalData({ ...res, layoutid, targetid, drillid });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const graphOptions3 = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Project Earnings in April",
      // align: 'left',
      marginRight: 60,
      x: -150,
      style: {
        fontFamily: "sans-serif",
      },
    },
    tooltip: {
      pointFormat: "{series.data.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    legend: {
      title: "Money Shared",
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      symbolWidth: 18,
      useHTML: true,
      labelFormatter: function () {
        let name =
          this.name.length > 20 ? this.name.substr(0, 20) + "..." : this.name;
        return (
          '<div className= "pt-4 pr-4" style="font-size:16px; width: 240px; display: flex;"><div style="float:left; width: 50%">' +
          `${name}` +
          '</div><div style="float:right; position: absolute; right: 0;">' +
          `${this.y}` +
          "</div></div>"
        );
      },
    },
    plotOptions: {
      pie: {
        borderColor: null,
        borderWidth: 12,
        slicedOffset: 20,
        innerSize: "90%",
        dataLabels: {
          enabled: false,
          connectorWidth: 0,
        },
        showInLegend: true,
      },
      series: {
        point: {
          events: {
            click: function () {
              // Call the custom function with the category as an argument
              showDetail(this.category);
            },
          },
        },
      },
    },
    series: [
      {
        //     data: [
        //         ['Leaf CRM', 44.2],
        //         ['Mivy App', 26.6],
        //         ['Others', 30]
      },
    ],
  };
  // const graphOptions = options2?.options;
  // const graphData = graphOptions[0];

  graphData &&
    graphData?.series.forEach((seriesItem) => {
      seriesItem.data.forEach((dataItem) => {
        // Check if y is a string, then convert it to a number
        if (typeof dataItem.y === "string") {
          dataItem.y = parseFloat(dataItem.y); // Use parseFloat() if y can have decimal values
          // Or use parseInt(dataItem.y) if y should be an integer
        }
      });
    });

  console.log("the graphoptions", graphData);
  console.log("the modalData is", modalData);

  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
        />
      )}
      <div>
        <header className="flex justify-between items-center mb-3">
          {/* <h2 className='font-bold uppercase'>{name}</h2>
        <div className='rounded-lg p-2' style={{backgroundColor:"#F1F2FB"}}>
            <FilterIcon fill="#aaaaaa" />
        </div> */}
        </header>
        <div id="graph" className="mb-10">
          {Object.keys(graphData?.series ?? {}).length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{ ...graphOptions3, ...graphData }}
              containerProps={{ style: { width: "100%" } }}
            />
          ) : (
            // options={{...graphOptions,"yAxis":{"title":{"enabled":false}}}} />
            <EmptyRecord />
          )}
        </div>
      </div>
    </>
  );
}

export default WithLoader(ChartControl3);
